<template>
  <div :class="this.dropzoneid !== 'vabotu-body' && [this.dropzoneid]">
    <div class="upload-area dz-clickable"></div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import { apiUrl, fileValidations } from "@/config";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import has from "lodash/has";
import filter from "lodash/filter";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import RandomPosition from "random-position";
import { pusher } from "@/pusher";

import {
  NewMediaQuery,
  DeleteMediaMutation,
  DeleteBulkMediaMutation,
  UpdateMediaMutation,
} from "@/gql";

export default {
  name: "FileUploader",
  mixins: [FileExtensionMixin, ProxyModelMixin],
  api: {
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
  },
  props: [
    "authToken",
    "workspace",
    "noquery",
    "user",
    "isWorkspaceOwner",
    "labels",
    "dropzoneid",
    "uploadArea",
    "fromComputer",
    "totalFile",
    "isMediaViewUploader",
  ],
  data() {
    return {
      fileSuccessCounter: 0,
      fileAddCounter: 0,
      dropzone: null,
      upgradeStorageDialog: false,
      progress: 0,
      fileList: {},
      isDialogClosed: false,
      requestSent: null,
      maxFiles: 10,
      uploaded: 0,
      requestFiles: {},
      isQueuecomplete: false,
      currentViewMedia: null,
      videoMediaData: null,
      displayPlayer: false,
    };
  },
  mounted() {
    if (this.dropzoneid === "vabotu-body") {
      this.initDropzone();
    }
  },
  beforeDestroy() {
    if (this.dropzoneid === "vabotu-body") {
      this.destroyDropzone();
    }
  },
  methods: {
    onDragStart() {
      document.body.classList.add("dragging");
    },
    onDragEnd() {
      document.body.classList.remove("dragging");
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [];

      if (event.from === event.to) tmp.splice(oldIndex, 1);
      tmp.splice(newIndex, 0, media);
      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );

      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
    },
    initDropzone() {
      this.dropzone = new Dropzone(`.${this.dropzoneid}`, {
        url: `${apiUrl}/api/media`,
        method: "post",
        maxFilesize: fileValidations.maxSize / 8000,
        accept: (file, done) => {
          if (file.size / 1000 <= fileValidations.minSize) {
            done(this.$t("message.fileupload.dictFileTooSmall"));
            this.fileSuccessCounter += 1;
          } else {
            done();
          }
        },
        autoDiscover: false,
        parallelUploads: 30,
        timeout: 300000,
        uploadMultiple: false,
        previewsContainer: false,
        maxThumbnailFilesize: 25,
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        headers: {
          Authorization: `Bearer ${this.authToken}`,
          Pusher: pusher.connection.socket_id,
        },
        paramName: "file",
        dictFileTooBig: this.$t("message.fileupload.dictFileTooBig"),
        dictCancelUploadConfirmation: this.$t(
          "message.fileupload.dictCancelUploadConfirmation"
        ),
        ...(this.proxyModel && {
          clickable: `.${this.uploadArea ? this.uploadArea : "upload-area"}`,
        }),
      });
      /**
       * Overide the event of dropzone
       */
      this.dropzone.on("processing", (file) => {
        if (this.isMediaViewUploader) {
          this.$q.loadingBar.start();
        }
        this.$emit(
          "totalFileCount",
          Object.getOwnPropertyNames(this.requestFiles).length - 1
        );
        if (has(this.requestFiles, file.upload.uuid)) {
          this.requestFiles[file.upload.uuid].processing = true;
        }
      });
      this.dropzone.on("sending", (file, xhr, formdata) => {
        if (has(this.requestFiles, file.upload.uuid)) {
          this.requestFiles[file.upload.uuid].sending = true;
        }
        formdata.append("workspace_id", this.currentWorkspace);
        this.requestSent = true;
      });

      this.dropzone.on("dragover", (e) => {
        if (!e.dataTransfer.types.includes("Files")) {
          return;
        }
        this.$emit("showUploader", true);
        const items = Array.from(e.dataTransfer.items);
        if (
          items.every((item) => item.kind === "file") &&
          document.body.classList.contains("vabotu-body")
        ) {
          this.proxyModel = true;
        }
      });
      this.dropzone.on("dragleave", (e) => {
        this.$emit("showUploader", false);
        if (e.clientX === 0 && e.clientY === 0) {
          this.proxyModel = false;
        }
        if (e.target.classList && e.target.classList.contains("overlay")) {
          this.proxyModel = false;
        }
      });
      this.dropzone.on("addedfile", (file) => {
        this.$emit("showUploader", false);
        this.$set(this.requestFiles, file.upload.uuid, {
          name: file.name,
          id: null,
          thumb: null,
          progress: 0,
          uuid: file.upload.uuid,
          type: file.type,
          error: null,
          processing: false,
          sending: false,
          success: false,
          verifyKey: "uploading",
        });
        if (!file.type.match(/image.*/)) {
          let src = this.transformMedia(file, true).type;
          this.dropzone.emit("thumbnail", file, src);
        }
      });

      this.dropzone.on("totaluploadprogress", (progress) => {
        this.progress = Math.min(progress, 99);
        if (this.isMediaViewUploader) {
          this.$q.loadingBar.increment(this.progress);
        }
        this.$emit("progress", this.progress);
      });
      this.dropzone.on("queuecomplete", () => {
        this.progress = Object.keys(this.requestFiles).length === 0 ? 0 : 100;
        this.requestFiles = {};
        this.$emit("progress", this.progress);
        this.requestSent = null;
      });

      this.dropzone.on("success", (file, response) => {
        const uploadedFileCount =
          this.totalFile - this.dropzone.getUploadingFiles().length;
        this.$emit("updateUploadCount", uploadedFileCount);
        if (response && response.data) {
          if (has(this.requestFiles, file.upload.uuid)) {
            this.requestFiles[file.upload.uuid].id = response.data.id;
            this.requestFiles[file.upload.uuid].success = true;
          } else {
            this.removeMedia({ id: response.data.id });
          }
          file.postId = response.data.id;
          this.fileList[response.data.id] = {
            ...response.data,
          };
          // this.addToMediaCache(response.data);
          this.requestSent = false;
          this.fileSuccessCounter += 1;
          this.uploaded += 1;
          if (this.uploaded === this.maxFiles) {
            this.fileAddCounter = this.fileSuccessCounter;
          }
          if (response.data.thumb) {
            this.dropzone.emit("thumbnail", file, response.data.thumb);
          }
          this.addFileToUploader();
          this.$emit("done", this.fileList);
        }
      });

      this.dropzone.on("error", (file, response) => {
        if (has(this.requestFiles, file.upload.uuid)) {
          if (response.status_code === 403) {
            this.upgradeStorageDialog = true;
          }
          this.requestFiles[file.upload.uuid].error = response;
        }
      });

      /**
       * create custom thumbnail for non-image file
       */

      this.dropzone.on("thumbnail", (file, dataUrl) => {
        if (typeof dataUrl === "object") {
          let src = this.transformMedia(file, true).type;
          file.previewElement.querySelector("img").src = src;
        }
        if (has(this.requestFiles, file.upload.uuid)) {
          this.requestFiles[file.upload.uuid].thumb = dataUrl;
        }
      });
    },
    destroyDropzone() {
      if (this.dropzone) {
        this.dropzone.destroy();
      }
    },
    addFileToUploader(files) {
      let data;
      for (var media in files) {
        data = {};
        files[media]["verifyKey"] = "fromShared";
        files[media]["name"] = files[media].filename;
        data[files[media].id] = files[media];
        const fileList = this.fileList;
        const requestFiles = this.requestFiles;
        this.requestFiles = { ...requestFiles, ...data };
        this.fileList = { ...fileList, ...data };
      }
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        this.$refs.mediaCommentingView.$refs.commentBox.find(
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: item.media.id,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
    closeDialog(flag) {
      if (flag) {
        if (this.noquery) {
          this.$emit("close", true);
        } else {
          this.$emit("close", this.fileList);
        }
      } else {
        let requestFiles = filter(
          this.requestFiles,
          (a) => a["verifyKey"] === "uploading"
        );
        this.bulkRemove(requestFiles);
        this.dropzone.removeAllFiles(true);
        this.proxyModel = false;
        this.$emit("cancel");
      }
    },
    updateMediaCommentPointLabel(themedata) {
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    async removeMedia(file) {
      if (this.currentViewMedia && has(this.fileList, file.id)) {
        if (this.fileList[file.id].id === this.currentViewMedia.id) {
          this.currentViewMedia = null;
        }
      }
      if (file["verifyKey"] === "fromShared") {
        if (has(this.requestFiles, file.id)) {
          this.$delete(this.requestFiles, file.id);
        }
        const fileList = this.fileList;
        delete fileList[file.id];
        this.fileList = { ...fileList };
      } else {
        const dropzone = this.dropzone;
        if (has(this.requestFiles, file.uuid)) {
          this.$delete(this.requestFiles, file.uuid);
          const removeFileObj = dropzone.files.find(
            (obj) => obj.upload.uuid === file.uuid
          );
          if (file.processing === false) {
            dropzone.removeFile(removeFileObj);
          }
          // removeFile
        }
        if (Object.keys(this.fileList).length === 0) {
          this.progress = 0;
          this.$emit("progress", this.progress);
          this.requestSent = null;
        }
        if (file.id) {
          const fileList = this.fileList;
          delete fileList[file.id];
          this.fileList = { ...fileList };

          let id = file.id;

          await this.$api.mutate({
            mutation: DeleteMediaMutation,
            variables: { id },
          });

          const query = this.$api.getQuery(
            `MediaQuery:${this.currentWorkspace}`
          );
          query.data.newMedia = query.data.newMedia.filter((f) => f.id !== id);
        }
      }
    },
    async bulkRemove(fileList) {
      if (fileList) {
        const variables = {
          ids: fileList
            ? fileList.map((f) => f.id).filter((id) => id !== null)
            : [],
        };
        if (variables.ids.length > 0) {
          await this.$api.mutate({
            mutation: DeleteBulkMediaMutation,
            variables,
          });

          const query = this.$api.getQuery(
            `MediaQuery:${this.currentWorkspace}`
          );
          query.data.newMedia = query.data.newMedia.filter(
            (f) => !variables.ids.includes(f.id)
          );
        }
      }
    },
    async updateMediaList() {
      await this.$api.query({
        query: NewMediaQuery,
        variables: {
          workspace_id: this.currentWorkspace,
        },
      });
    },
    openFileDialog() {
      if (!this.dropzone) {
        this.initDropzone();
      }
      this.dropzone.hiddenFileInput.click();
    },
    cancelUpload() {
      let requestFiles = filter(
        this.requestFiles,
        (a) => a["verifyKey"] === "uploading"
      );
      this.bulkRemove(requestFiles);
      this.dropzone.removeAllFiles(true);
      this.$emit("cancel");
      this.fileList = {};
      this.requestFiles = {};
    },
  },
  computed: {
    selectedMediaForComment() {
      return this.currentViewMedia;
    },
    currentWorkspace() {
      return this.workspace;
    },
    progressBar() {
      return this.progress;
    },
    uploadingFiles() {
      return this.requestFiles;
    },
  },
  watch: {
    proxyModel(to) {
      if (to === false) {
        this.dropzone.removeAllFiles();
        this.fileSuccessCounter = 0;
        this.fileAddCounter = 0;
        this.progress = 0;
        this.$emit("progress", this.progress);
        this.uploaded = 0;
        if (this.selectedMediaForComment !== null) {
          this.$emit("closeMediaModel", {
            id: this.selectedMediaForComment.id,
          });
        }
        if (this.fromComputer) {
          this.$emit("cancleFromComputer");
        }
      } else {
        this.fileList = {};
        this.requestFiles = {};
        this.requestSent = null;
        this.currentViewMedia = null;
        this.progress = 0;
        this.$emit("progress", this.progress);
      }
    },
  },
};
</script>
