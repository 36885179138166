<template>
  <q-item
    @click.stop="attachmentPreviewHandler(media)"
    class="
      group-hover
      q-px-none
      task-attachment-block
      items-center
      q-mr-sm q-mb-sm q-pr-sm
    "
    clickable
  >
    <div class="row full-width cursor-pointer">
      <q-item-section
        style="min-width: 40px"
        class="q-pr-none media-thumbnail"
        avatar
      >
        <q-avatar size="40px" rounded>
          <img
            :src="isDropbox || isGoogle ? media.thumb : imgSrc"
            alt="avatar"
            style="border-radius: 6px"
          />
          <div
            v-if="isVideo && !isDropbox"
            class="row justify-center items-center absolute video-icon"
          >
            <div class="text-center flex-1">
              <q-btn
                :icon="$icons.mdiPlay"
                color="primary"
                size="sm"
                round
                dense
                padding="0"
                flat
              />
            </div>
          </div>
          <div
            class="absolute media-comments-badge-task-attachment"
            v-if="media.type.match(/image.*/) && media.commentPoint.length > 0"
            :style="
              media.commentPoint.length == completedMediaPoints.length
                ? `background: #15d89a`
                : ''
            "
          >
            <span>
              {{
                completedMediaPoints.length + "/" + media.commentPoint.length
              }}
            </span>
          </div>
        </q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-black task-attachment-file-name">{{
          media.filename
        }}</q-item-label>
        <!-- <q-item-label style="font-size:11px;color:#575D64" caption>
          {{ media.size | filesizeFormat }}mb |
          {{ fileSharedDate }}</q-item-label
        > -->
      </q-item-section>
    </div>
    <div
      class="
        group-hover-item
        task-attachment-menu
        row
        items-center
        justify-between
        flex-no-wrap
        q-px-sm
      "
    >
      <div>
        <q-item @click.stop="confirm = true" clickable style="padding:6px">
          <q-icon size="13px" class="text-red" :name="$icons.fasTrash">
          </q-icon>
        </q-item>
      </div>
      <q-btn
        dense
        flat
        round
        color="grey-10"
        padding="0"
        :icon="$icons.matMoreVert"
        size="sm"
        class="three-dot-menu"
        @click.stop
      >
        <q-menu
          content-class="overflow-auto"
          v-model="attachmentMenu"
          auto-close
        >
          <q-list>
            <q-item @click="downloadFile(mediaId)" clickable>
              <q-item-section> Download </q-item-section>
            </q-item>
            <q-item @click="$copyTextToClipboard(media.file)" clickable>
              <q-item-section> Copy Link </q-item-section>
            </q-item>
            <!-- <q-item @click="fromComputer" clickable v-if="isImg">
              <q-item-section> Replace image </q-item-section>
            </q-item> -->
            <q-item @click="confirm = true" clickable class="text-red">
              <q-item-section> Remove </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <confirm-dialog
      v-model="confirm"
      title="Remove Attachment?"
      question="Are you sure you want to delete this attachment?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => (confirm = false)"
      :successMethod="detachAttachment"
      :loading="false"
    />
  </q-item>
</template>
<script>
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";

import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { getDownloadUrl } from "@/services/downloadS3File";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";

export default {
  name: "TaskAttachment",
  mixins: [FileExtensionMixin],
  props: [
    "media",
    "size",
    "fullview",
    "pdfView",
    "videoPlayer",
    "audioPlayer",
    "detachTaskAttachmentHandler",
    "fromComputer",
  ],
  data() {
    return {
      confirm: false,
      attachmentMenu: false,
    };
  },
  components: {
    ConfirmDialog,
  },
  methods: {
    async downloadFile(id) {
      this.attachmentMenu = false;
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
    },
    detachAttachment() {
      this.detachTaskAttachmentHandler(this.media.id);
      this.confirm = false;
    },
    attachmentPreviewHandler(media) {
      if (this.isImg) {
        this.fullview(media);
      } else if (this.isAudio) {
        this.audioPlayer(this.media);
      } else if (this.isVideo) {
        this.videoPlayer(media);
      } else if (!this.isImg && !this.isVideo && !this.isZip) {
        this.pdfView(media);
      }
    },
  },
  computed: {
    isZip() {
      return this.media.type.match(/zip.*/);
    },
    isMusicFile() {
      return this.media.type.match(/wav.*/) || this.media.type.match(/mp3.*/);
    },
    mediaId() {
      return this.media.id;
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isAudio() {
      return this.media.type.match(/audio.*/);
    },
    isGoogle() {
      return this.media.file_resource === "google";
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    filename() {
      return this.media.filename;
    },
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      // Used Mixin : FileExtensionMixin
      return this.mediaPreview.type;
    },
    fileSharedDate() {
      return format(fromUnixTime(this.media.created_at), "MMM. do @hh:mm aaaa");
    },
    getMedia() {
      if (this.media) {
        this.media.commentPoint.forEach((commentPoint) => {
          commentPoint["comments"] = [];
          commentPoint["menuFlag"] = false;
          if (commentPoint.last_label === "#15d89a") {
            commentPoint["completed"] = true;
          } else {
            commentPoint["completed"] = false;
          }
        });
      }
      return this.media;
    },
    completedMediaPoints() {
      return this.getMedia.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.media-card-bg {
  background: #E6E9EE;
}
</style>
