<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="$emit('closeDialog')"
    :value="value"
  >
    <q-card style="min-width:320px;" class="create-folder">
      <div class="row title q-mb-md">
        <span class="q-mx-auto" v-if="bookmarkFolder"
          >Rename bookmark folder</span
        >
        <span v-else class="q-mx-auto">Create bookmark folder</span>
      </div>
      <q-input
        placeholder="Type folder name"
        dense
        outlined
        autofocus
        v-model="folderName"
        @keydown.enter.exact="
          createBookmarkFolderEvent();
          $event.preventDefault();
        "
      />
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="$emit('closeDialog')"
        />
        <q-btn
          v-if="bookmarkFolder"
          color="primary"
          dense
          no-caps
          label="Update"
          style="width:120px"
          @click="createBookmarkFolderEvent"
          :loading="loading"
          :disable="!this.folderName"
        />
        <q-btn
          v-else
          color="primary"
          dense
          no-caps
          label="Create folder"
          class="create-folder-btn"
          @click="createBookmarkFolder"
          :loading="loading"
          :disable="this.folderName ? false : true"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import {
  CreateBookmarkFolderMutation,
  UpdateBookmarkFolderMutation,
} from "@/gql";

export default {
  name: "CreateBookmarkFolderDialog",
  props: ["value", "user", "workspace", "bookmarkFolder", "handleResize"],
  data() {
    return {
      folderName: null,
      loading: false,
    };
  },
  mounted() {
    if (this.bookmarkFolder) {
      this.folderName = this.bookmarkFolder.title;
    }
  },
  methods: {
    createBookmarkFolderEvent() {
      if (this.bookmarkFolder) {
        this.editBookmarkFolderHandler();
      } else {
        this.createBookmarkFolder();
      }
    },
    async createBookmarkFolder() {
      this.loading = true;
      try {
        const bookmarkFoldersQuery = this.$api.getQuery(
          `BookmarkFoldersQuery:${this.workspace.id}`
        );

        const response = await this.$api.mutate({
          mutation: CreateBookmarkFolderMutation,
          variables: {
            title: this.folderName,
            workspace_id: this.workspace.id,
          },
        });

        if (bookmarkFoldersQuery.data.bookmarkFolders) {
          bookmarkFoldersQuery.data.bookmarkFolders.unshift(
            response.data.createBookmarkFolder
          );
        }
        this.handleResize();
        this.loading = false;
        this.$emit("closeDialog");
      } catch (error) {
        this.$emit("closeDialog");
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async editBookmarkFolderHandler() {
      this.loading = true;
      const bookmarkFolder = this.$api.getEntity(
        "bookmarkFolder",
        this.bookmarkFolder.id
      );
      bookmarkFolder.title = this.folderName;
      await this.$api.mutate({
        mutation: UpdateBookmarkFolderMutation,
        variables: {
          title: this.folderName,
          id: this.bookmarkFolder.id,
        },
      });
      this.handleResize();
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
</style>
