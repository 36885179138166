<template>
  <q-btn
    flat
    :icon="$icons.matSentimentSatisfied"
    size="12px"
    class="thread-icon"
    rounded
    dense
    @click="openReactionMenu"
  >
    <q-menu content-class="overflow-auto apply-width" v-model="showReaction" v-if="showReaction">
      <div class="card react-emoji-card">
        <img
          v-for="(emoji, index) in reactions"
          :key="index"
          :src="'/static/react-icons/' + emoji.icon"
          alt="ANGRY"
          @click="$emit('reactOnPost', { post, reaction: emoji }); showReaction=false;"
          class="react-emoji-icon q-mr-sm cursor-pointer"
        />
      </div>
    </q-menu>
    <q-tooltip>
      Add Reaction
    </q-tooltip>
  </q-btn>
</template>

<script>
export default {
  data() {
    return {
      showReaction : false,
    }
  },
  name: "MessageReactionDialog",
  props: [
    "reactions",
    "post"
  ],
  methods:{
    openReactionMenu() {
      this.showReaction = true;
    }
  },
};
</script>
