<template>
  <div
    class="border-radius10 relative-position q-mr-sm media-card-hover cursor-pointer"
    style="width: 108px;height: 108px"
    @click="$emit('openfolder', folder)"
  >
    <q-tooltip>
      {{ folder.title }}
    </q-tooltip>
    <div
      class="absolute-full fill-height full-width folder-hover-item media-card-hover-item cursor-pointer"
      style="height: 108px;border-radius:10px"
    >
      <div
        class="task-menu-option thread-icons q-px-xs q-mt-sm q-mr-xs group-hover-item row items-center"
        style="z-index: 100"
      >
        <div v-if="folder.isbookmarked" @click.stop="removeMediaFolderBookmark">
          <q-icon
            size="20px"
            class="cursor-pointer"
            color="pink"
            :name="$icons.matBookmark"
          >
            <q-tooltip>
              Remove from Saved items
            </q-tooltip>
          </q-icon>
        </div>
        <div v-if="!folder.isbookmarked" @click.stop="addMediaFolderBookmark">
          <q-icon
            size="20px"
            class=" cursor-pointer"
            color="black"
            :name="$icons.matBookmarkBorder"
          >
            <q-tooltip>
              Add to Saved items
            </q-tooltip>
          </q-icon>
        </div>
        <q-btn
          text-color="black"
          size="13px"
          round
          dense
          padding="0"
          class="q-button-hover"
          :icon="$icons.matMoreHoriz"
          @click.stop
        >
          <q-menu content-style="min-width:150px" auto-close>
            <q-list>
              <q-item
                @click="copyMoveFileFolderHandler([folder], true, true, data)"
                clickable
                v-if="!isSavedMessageMedia"
              >
                <q-item-section> Copy </q-item-section>
              </q-item>
              <q-item
                clickable
                class="q-mb-sm"
                @click="$emit('renameFolder', folder)"
                v-close-popup
              >
                <q-item-section>
                  Rename
                </q-item-section>
              </q-item>
              <q-item
                clickable
                class="text-red"
                @click="detachFolder(folder.id, post)"
              >
                <q-item-section> Remove </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>
    <div class="post-folder column justify-center items-center">
      <div class="relative-position">
        <q-icon :name="$icons.matFolder" class="post-folder-icon" />
        <div class="post-folder-count">
          {{ folder.media_count > 9 ? "9+" : folder.media_count }}
        </div>
      </div>
      <!-- <div class="post-folder-title ellipses">
        {{ folder.title }}
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  AddMediaFolderBookmarkMutation,
  RemoveMediaFolderBookmarkMutation,
} from "@/gql";
export default {
  name: "PostFolder",
  props: [
    "folder",
    "detachFolder",
    "copyMoveFileFolderHandler",
    "data",
    "post",
    "isSavedMessageMedia",
  ],
  methods: {
    async addMediaFolderBookmark() {
      try {
        const variables = {
          media_folder_id: this.folder.id,
        };
        const folderEntity = this.$api.getEntity("mediaFolder", this.folder.id);
        folderEntity.isbookmarked = !folderEntity.isbookmarked;
        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.media_folder.push(this.folder);
        await this.$api.mutate({
          mutation: AddMediaFolderBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaFolderBookmark() {
      try {
        const variables = {
          media_folder_id: this.folder.id,
        };
        const folderEntity = this.$api.getEntity("mediaFolder", this.folder.id);
        folderEntity.isbookmarked = !folderEntity.isbookmarked;
        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.media_folder = savedItemsQuery.data.savedItems.media_folder.filter(
          (item) => item.id != this.folder.id
        );
        await this.$api.mutate({
          mutation: RemoveMediaFolderBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
