<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="$emit('closeDialog')"
    :value="value"
  >
    <q-card style="min-width:320px;" class="create-folder">
      <div class="row title q-mb-md">
        <span v-if="bookmark" class="q-mx-auto"
          >Edit bookmark to this workspace</span
        >
        <span v-else class="q-mx-auto"> Add bookmark to this workspace</span>
      </div>
      <div class="">
        <q-input
          type="text"
          placeholder="Bookmark name"
          ref="focusname"
          dense
          outlined
          autofocus
          bottom-slots
          no-error-icon
          v-model="bookmarkName"
          @blur="$v.bookmarkName.$touch()"
          :error="fieldErrors('bookmarkName').length > 0"
          @keydown.enter.exact="
            createBookmarkEvent();
            $event.preventDefault();
          "
        >
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("bookmarkName").length > 0
                  ? fieldErrors("bookmarkName")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
      </div>
      <div class="q-mt-sm">
        <q-input
          type="url"
          placeholder="Bookmark link"
          dense
          outlined
          v-model="bookmarkLink"
          bottom-slots
          no-error-icon
          @blur="$v.bookmarkLink.$touch()"
          :error="fieldErrors('bookmarkLink').length > 0"
          @keydown.enter.exact="
            createBookmarkEvent();
            $event.preventDefault();
          "
        >
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("bookmarkLink").length > 0
                  ? fieldErrors("bookmarkLink")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
      </div>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="$emit('closeDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Update"
          style="width:120px"
          v-if="bookmark"
          @click="createBookmarkEvent"
          :loading="loading"
          :disable="$v.$invalid || loading || !bookmarkName || !bookmarkLink"
        />
        <q-btn
          v-else
          color="primary"
          dense
          no-caps
          label="Add"
          class="create-folder-btn"
          @click="createBookmark"
          :loading="loading"
          :disabled="$v.$invalid || loading"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { CreateBookmarkMutation, UpdateBookmarkMutation } from "@/gql";
import { required, url } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";

export default {
  name: "CreateBookmarkDialog",
  props: ["value", "user", "workspace", "folderId", "bookmark", "handleResize"],
  mixins: [validationMixin],
  validations: {
    bookmarkName: { required },
    bookmarkLink: { required, url },
  },
  validationMessages: {
    bookmarkName: {
      required: "message.validation.bookmark.title.required",
    },
    bookmarkLink: {
      required: "message.validation.bookmark.url.required",
      url: "message.validation.bookmark.url.url",
    },
  },
  data() {
    return {
      bookmarkName: null,
      bookmarkLink: null,
      loading: false,
    };
  },
  mounted() {
    if (this.bookmark) {
      this.bookmarkName = this.bookmark.title;
      this.bookmarkLink = this.bookmark.link;
    }
    this.$nextTick(() => {
      this.$refs.focusname.focus();
    });
  },
  methods: {
    createBookmarkEvent() {
      console.log("this.$v.$invalid", this.$v.$invalid);
      if(!this.$v.$invalid && this.bookmarkName && this.bookmarkLink) {
        if (this.bookmark) {
          this.renameBookmarkHandler();
        } else {
          this.createBookmark();
        }
      }
    },
    async createBookmark() {
      this.loading = true;
      try {
        let data = {
          title: this.bookmarkName,
          link: this.bookmarkLink,
          workspace_id: this.workspace.id,
        };

        if (this.folderId) {
          data.bookmark_folder_id = this.folderId;
        }

        const response = await this.$api.mutate({
          mutation: CreateBookmarkMutation,
          variables: data,
        });

        if (this.folderId) {
          //const bookmarkFolder = this.$api.getQuery(`BookmarkFolders:${this.workspace.id}`);
          const bookmarkFolder = this.$api.getEntity(
            "bookmarkFolder",
            this.folderId
          );

          if (bookmarkFolder) {
            bookmarkFolder.bookmarks.push(response.data.createBookmark);
          }
        } else {
          const bookmarkQuery = this.$api.getQuery(
            `BookmarksQuery:${this.workspace.id}`
          );

          if (bookmarkQuery.data.bookmarks) {
            bookmarkQuery.data.bookmarks.push(response.data.createBookmark);
          }
        }
        this.handleResize();
        this.loading = false;
        this.$emit("closeDialog");
      } catch (error) {
        this.$emit("closeDialog");
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async renameBookmarkHandler() {
      this.loading = true;
      const bookmark = this.$api.getEntity("bookmark", this.bookmark.id);
      bookmark.title = this.bookmarkName;
      bookmark.link = this.bookmarkLink;
      await this.$api.mutate({
        mutation: UpdateBookmarkMutation,
        variables: {
          title: this.bookmarkName,
          link: this.bookmarkLink,
          id: this.bookmark.id,
        },
      });
      this.handleResize();
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
</style>
