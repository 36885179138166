<template>
  <div
    class="rounded-borders q-py-sm bg-white justify-between"
    style="min-height: 40px; height: 43px; padding-left: 20px"
  >
    <div class="row flex-no-wrap">
      <div
        class="row flex-no-wrap items-center q-ml-sm q-mr-md pin-button cursor-pointer"
      >
        <span>
          <q-icon :name="$icons.fasThumbtack" />
        </span>
        <span style="white-space: nowrap">
          {{ pinPost.length + " Pinned" }}
        </span>

        <q-tooltip> Show pinned: {{ pinPost.length }} </q-tooltip>
        <q-menu
          v-model="showPinPost"
          content-class="overflow-auto pin-message-menu"
          content-style="min-width:168px"
          style="max-width: 200px"
          :offset="[50, 0]"
        >
          <q-list class="flex-1" style="width: 500px">
            <template v-for="post in pinPost">
              <q-item :key="'pinpost-' + post.id" clickable>
                <div
                  class="row thread-message group-hover group-background-hover-item editor-prototype relative-position full-width pinned-item"
                >
                  <div class="row flex-no-wrap full-width">
                    <div class="">
                      <div
                        class="absolute"
                        style="right: 25px; top: 0px"
                        v-if="!post.is_bot"
                      >
                        <message-reaction-dialog
                          @reactOnPost="reactOnMessage"
                          :post="post"
                          :reactions="reactions"
                        ></message-reaction-dialog>
                        <q-btn
                          flat
                          :icon="$icons.fasCheck"
                          size="xs"
                          class="thread-icon"
                          rounded
                          dense
                          v-if="!post.is_task"
                          @click="createTaskFromMessageHandler(post)"
                        >
                          <q-tooltip> Create a task </q-tooltip>
                        </q-btn>
                        <q-btn
                          flat
                          class="bookmark"
                          :icon="$icons.matBookmark"
                          size="sm"
                          rounded
                          text-color="pink"
                          color="pink"
                          dense
                          padding="5px"
                          v-if="post.isbookmarked"
                          @click="removeMessageBookmark(post)"
                        >
                          <q-tooltip> Remove from Saved items </q-tooltip>
                        </q-btn>
                        <q-btn
                          flat
                          :icon="$icons.matBookmarkBorder"
                          size="sm"
                          class="thread-icon"
                          padding="5px"
                          rounded
                          color="black"
                          dense
                          v-if="!post.isbookmarked"
                          @click="addMessageBookmark(post)"
                        >
                          <q-tooltip> Add to Saved items </q-tooltip>
                        </q-btn>
                      </div>
                      <q-btn
                        flat
                        size="sm"
                        round
                        dense
                        padding="0"
                        style="color: #fff"
                        class="absolute q-mr-xs q-mt-xs"
                        :icon="$icons.matClose"
                        @click="deletePinMessageDialogHandler(true, post)"
                      >
                        <q-tooltip> Remove pinned message </q-tooltip>
                      </q-btn>
                    </div>
                    <div class="row items-start">
                      <template v-if="post.is_bot">
                        <q-avatar class="q-ml-md" size="35px">
                          <img
                            src="https://s3-us-west-1.amazonaws.com/vabotu/default/heycollab-round.svg"
                            alt="Heycollab"
                            class="text-avatar-style"
                          />
                        </q-avatar>
                      </template>
                      <!-- Not bot -->
                      <template v-else>
                        <!-- <div style="margin-top:12px">
                          <div
                            slot="activator"
                            v-if="
                              (hasNotification || hasMessageUnread) &&
                                user &&
                                user.id !== post.user.id
                            "
                            class="cursor-pointer notification-sidebar-dot q-mr-sm pos-absolute"
                            @click="markMessageAsRead"
                          >
                            <q-tooltip>
                              Mark read
                            </q-tooltip>
                          </div>
                          <div
                            v-else-if="user && user.id !== post.user.id"
                            slot="activator"
                            class="cursor-pointer notification-sidebar-outline-dot q-mr-sm pos-absolute"
                            @click="markMessageAsUnread"
                          >
                            <q-tooltip>
                              Mark unread
                            </q-tooltip>
                          </div>
                        </div> -->
                        <q-avatar
                          size="35px"
                          color="blue-grey-11"
                          text-color="grey-10"
                          class="q-ml-md"
                        >
                          <img
                            :src="post.user.pic"
                            :alt="post.user.first"
                            v-if="post.user.pic"
                          />
                          <!-- <span class="text-bold text-subtitle1" v-else>
                            {{ post.user.first | firstChar
                            }}{{ post.user.last | firstChar }}
                          </span> -->
                          <avatar v-else :size="35" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="post.user.first+' '+post.user.last"></avatar>
                        </q-avatar>
                      </template>
                    </div>
                    <div class="thread-middle-section flex-1 q-pl-sm">
                      <!-- Post User Detail -->
                      <div
                        class="messaging-user-detail-chip"
                        v-if="post.is_bot"
                      >
                        <span class="user-name text-black">Heycollab</span>
                        <span class="time">
                          <span>{{ post.created_at | gettime }}</span>
                        </span>
                      </div>

                      <div class="messaging-user-detail-chip" v-else>
                        <span class="user-name text-black">{{
                          post.user.first
                        }}</span>
                        <span class="time">
                          <span v-if="post.updated_at > post.created_at">
                            {{ post.created_at | gettime }}
                            <span class="capitalize-text an-12">(Edited)</span>
                          </span>
                          <span v-else>{{ post.created_at | gettime }}</span>
                        </span>
                        <q-btn
                          v-if="post.is_email_to_task"
                          flat
                          :icon="$icons.matEmail"
                          size="11px"
                          class="q-ml-xs"
                          rounded
                          dense
                          style="color: #a1acb8"
                        >
                          <q-tooltip>Message sent from e-mail</q-tooltip>
                        </q-btn>
                      </div>
                      <!-- Normal Message -->
                      <div
                        class="post-content mt-0 dont-break-out"
                        v-if="post.content"
                        v-html="PostHtml(post.content)"
                        style="white-space: pre-line"
                      ></div>
                      <!-- Media Attachment -->
                      <post-media
                        v-if="
                          post.media.length ||
                          (post.mediafolder && post.mediafolder.length)
                        "
                        :attachments="post.media"
                        :post="post"
                        :openFolder="openFolder"
                        :deleteFolderHandler="deleteFolderHandler"
                        :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                        :canRemoveMedia="
                          canRemoveMessageFromWorkspace ||
                          isWorkspaceOwner ||
                          (user && user.id === post.user.id)
                        "
                        :forceDisableRemove="
                          post.content === '' && post.media.length <= 1
                        "
                        :removeMedia="removeMedia"
                        :postId="post.id"
                        :labels="labels"
                        :user="user"
                        :workspace="currentWorkspace"
                        :isWorkspaceOwner="isWorkspaceOwner"
                        :fromComputer="fromComputer"
                        :editDoc="editDoc"
                        @closeMediaModel="
                          (data) => $emit('closeMediaModel', data)
                        "
                        @clickonmoreoption="clickonmoreoption"
                        @deleteCommentPoint="deleteCommentPoint"
                        @sendComment="sendComment"
                        @updatepositions="updatepositions"
                        @updateMediaCommentPointLabel="
                          updateMediaCommentPointLabel
                        "
                        @deleteMediaComment="deleteMediaComment"
                        @updateComment="updateComment"
                      />
                      <!-- Forward Section -->
                      <div
                        v-if="post.forwardedPost"
                        class="forword-post-layout q-pa-md q-mt-sm"
                        style=""
                      >
                        <!-- Forwarded Post User section -->
                        <div class="row">
                          <div class="flex-1">
                            <div class="messaging-user-detail-chip">
                              <span class="user-name cursor-pointer">
                                {{ post.forwardedPost.owner.first }}
                              </span>
                              <span class="time">
                                <span class="capitalize-text">
                                  {{
                                    post.forwardedPost.created_at
                                      | forwardPostTime
                                  }}
                                </span>
                              </span>
                            </div>
                            <!-- Forwarded Post Content -->
                            <div
                              class="post-content dont-break-out"
                              v-if="post.forwardedPost.content"
                              v-html="forwardPost(post.forwardedPost.content)"
                            ></div>
                            <template v-if="post.forwardedPost">
                              <post-media
                                v-if="
                                  post.forwardedPost.media &&
                                  post.forwardedPost.media.length
                                "
                                :post="post.forwardedPost"
                                :openFolder="openFolder"
                                :deleteFolderHandler="deleteFolderHandler"
                                :copyMoveFileFolderHandler="
                                  copyMoveFileFolderHandler
                                "
                                :attachments="
                                  reorderMedia(post.forwardedPost.media)
                                "
                                :canRemoveMedia="
                                  canRemoveMessageFromWorkspace ||
                                  isWorkspaceOwner ||
                                  (user && user.id === post.user.id)
                                "
                                :forceDisableRemove="true"
                                :removeMedia="removeMedia"
                                :postId="post.id"
                                :labels="labels"
                                :user="user"
                                :workspace="currentWorkspace"
                                :company="currentCompany"
                                :isWorkspaceOwner="isWorkspaceOwner"
                                :fromComputer="fromComputer"
                                :editDoc="editDoc"
                                @clickonmoreoption="clickonmoreoption"
                                @deleteCommentPoint="deleteCommentPoint"
                                @sendComment="sendComment"
                                @updatepositions="updatepositions"
                                @updateMediaCommentPointLabel="
                                  updateMediaCommentPointLabel
                                "
                                @deleteMediaComment="deleteMediaComment"
                                @updateComment="updateComment"
                                @closeMediaModel="
                                  (data) => $emit('closeMediaModel', data)
                                "
                                :class="{
                                  'mt-2':
                                    post.forwardedPost.content === null ||
                                    post.forwardedPost.content === '',
                                }"
                              />
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row flex-no-wrap justify-between items-center full-width"
                  >
                    <!-- Reactions List badges -->
                    <div
                      style="margin-left: 60px"
                      class="q-mt-sm"
                      v-if="
                        !Array.isArray(post.reactiondata) &&
                        Object.keys(post.reactiondata).length !== 0 &&
                        post.reactiondata.constructor === Object
                      "
                    >
                      <div class="row inline">
                        <template
                          v-for="(reactiondata, index) in post.reactiondata"
                        >
                          <div class="q-mr-sm" :key="index">
                            <div
                              class="relative-position reaction-icon row items-center"
                            >
                              <img
                                :src="`/static/react-icons/${index}.svg`"
                                :alt="index"
                                class="react-icon-size q-mr-xs cursor-pointer"
                              />
                              <div class="text-black" style="font-size: 12px">
                                {{ reactiondata.length }}
                              </div>
                            </div>
                            <q-tooltip
                              :offset="[-50, 0]"
                              content-class="bg-white elevation-2 q-pa-md"
                            >
                              <div class="row reaction-menu">
                                <img
                                  :src="`/static/react-icons/${index}.svg`"
                                  :alt="index"
                                  class="q-mr-md react-menu-icon"
                                />
                                <q-list
                                  class="column justify-center"
                                  v-if="reactiondata"
                                >
                                  <q-item
                                    :style="
                                      reactiondata.length === 1 && {
                                        'margin-bottom': '0px',
                                      }
                                    "
                                    class="q-pa-none q-mb-sm"
                                    style="min-height: 30px"
                                    v-for="item in reactiondata"
                                    :key="item.id"
                                  >
                                    <div class="row items-center">
                                      <div class="q-mr-sm">
                                        <q-avatar
                                          color="blue-grey-11"
                                          text-color="grey-10"
                                          size="26px"
                                        >
                                          <img
                                            :src="item.pic"
                                            :alt="item.first"
                                            v-if="item.pic"
                                          />
                                          <!-- <span
                                            class="text-avatar-style"
                                            style="font-size: 9px"
                                            v-else
                                          >
                                            {{ item.first | firstChar
                                            }}{{ item.last | firstChar }}
                                          </span> -->
                                          <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                                        </q-avatar>
                                      </div>
                                      <div class="reacted-user">
                                        {{ item.first }} {{ item.last }}
                                      </div>
                                      <div
                                        v-if="item.time"
                                        class="reaction-time q-ml-xs"
                                      >
                                        {{ item.time | formatDateTime }}
                                      </div>
                                    </div>
                                  </q-item>
                                </q-list>
                              </div>
                            </q-tooltip>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </q-item>
            </template>
          </q-list>
        </q-menu>
      </div>
      <div
        v-for="bookmark in filteredBookmarks"
        :key="bookmark.id"
        class="bookmark-button cursor-pointer q-mr-md"
        @click="openBookmarkURL(bookmark.link)"
      >
        <q-avatar size="20px" class="q-mr-xs">
          <img
            :src="
              'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&url=' +
              bookmark.link +
              '&size=32'
            "
          />
        </q-avatar>
        <span class="ellipsis">
          {{ bookmark.title }}
        </span>
        <q-tooltip>
          {{ bookmark.title }}
        </q-tooltip>
        <q-menu touch-position context-menu content-class="bookmark-options">
          <q-list dense>
            <q-item
              class="app-text-normal"
              clickable
              v-close-popup
              @click="editBookmark(bookmark)"
            >
              <q-item-section>Edit</q-item-section>
            </q-item>
            <q-item
              class="app-text-normal"
              clickable
              v-close-popup
              @click="deleteBookmarkOpen(bookmark)"
            >
              <q-item-section>Delete</q-item-section>
            </q-item>
            <q-item
              class="app-text-normal"
              clickable
              v-close-popup
              @click="$copyTextToClipboard(bookmark.link)"
            >
              <q-item-section>Copy link</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
      <div
        v-for="bookmarkFolder in bookmarkFoldersFiltered"
        :key="bookmarkFolder.id"
        class="bookmark-button cursor-pointer q-mr-md"
        @click="showTooltip = false;"
      >
        <span>
          <q-icon :name="$icons.mdiFolderOpen" />
        </span>
        <span class="ellipsis">
          {{ bookmarkFolder.title }}
          <q-tooltip v-if="showTooltip">
            {{ bookmarkFolder.title }}
          </q-tooltip>
        </span>

        <q-menu auto-close @before-hide="showTooltip = true">
          <q-list>
            <template v-for="bookmark in bookmarkFolder.bookmarks">
              <q-item
                :key="bookmark.id"
                clickable
                @click="openBookmarkURL(bookmark.link)"
                style="min-height: 24px"
              >
                <q-item-section side top class="q-pr-sm" v-if="bookmark.link">
                  <img
                    :src="
                      'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&url=' +
                      bookmark.link +
                      '&size=32'
                    "
                    style="width: 20px"
                  />
                </q-item-section>
                <q-item-section>
                  {{ bookmark.title }}
                </q-item-section>
                <q-menu
                  touch-position
                  context-menu
                  content-class="bookmark-options"
                >
                  <q-list dense>
                    <q-item
                      class="app-text-normal"
                      clickable
                      v-close-popup
                      @click="editBookmark(bookmark, bookmarkFolder.id)"
                    >
                      <q-item-section>Edit</q-item-section>
                    </q-item>
                    <q-item
                      class="app-text-normal"
                      clickable
                      v-close-popup
                      @click="deleteBookmarkOpen(bookmark, bookmarkFolder.id)"
                    >
                      <q-item-section>Delete</q-item-section>
                    </q-item>
                    <q-item
                      class="app-text-normal"
                      clickable
                      v-close-popup
                      @click="$copyTextToClipboard(bookmark.link)"
                    >
                      <q-item-section>Copy link</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item>
            </template>
            <template>
              <q-item
                clickable
                @click="
                  createBookmark.flag = true;
                  createBookmark.folder_id = bookmarkFolder.id;
                "
              >
                <q-item-section side top class="q-pr-xs">
                  <q-icon :name="$icons.matAdd"> </q-icon>
                </q-item-section>
                <q-item-section>Add bookmark</q-item-section>
              </q-item>
            </template>
          </q-list>
        </q-menu>
        <q-menu touch-position context-menu content-class="bookmark-options">
          <q-list dense>
            <q-item
              clickable
              v-close-popup
              @click="editBookmarkFolder(bookmarkFolder)"
            >
              <q-item-section>Edit</q-item-section>
            </q-item>
            <q-item
              clickable
              v-close-popup
              @click="deleteBookmarkFolderOpen(bookmarkFolder)"
            >
              <q-item-section>Delete</q-item-section>
            </q-item>
            <q-item
              clickable
              v-close-popup
              @click="
                createBookmark.flag = true;
                createBookmark.folder_id = bookmarkFolder.id;
              "
            >
              <q-item-section>Add bookmark</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
      <div
        @click="showTooltip = false"
        class="bookmark-button add-btn cursor-pointer"
      >
        <span>
          <q-icon :name="$icons.mdiPlusThick" />
        </span>
        <span>
          {{ !bookmarks.length ? "Add bookmark" : "" }}
        </span>

        <q-tooltip v-if="showTooltip"> Add bookmark </q-tooltip>
        <q-menu @before-hide="showTooltip = true" auto-close>
          <q-list>
            <q-item
              class="app-text-normal"
              clickable
              @click="createBookmark.flag = true"
            >
              <q-item-section
                class="q-pr-none"
                style="min-width: 35px !important"
                avatar
              >
                <q-icon :name="$icons.matLink" />
              </q-item-section>
              <q-item-section> Add bookmark </q-item-section>
            </q-item>
            <q-item
              clickable
              class="app-text-normal"
              @click="createBookmarkFolder.flag = true"
            >
              <q-item-section
                class="q-pr-none"
                style="min-width: 35px !important"
                avatar
              >
                <q-icon :name="$icons.matFolder" />
              </q-item-section>
              <q-item-section> Create bookmark folder </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
      <!-- More bookmarks menu -->
      <q-btn
        v-if="filteredBookmarks.length !== bookmarks.length"
        dense
        flat
        round
        padding="4px"
        :icon="$icons.matMoreHoriz"
        size="12px"
        class="more-menu"
        @click.stop
      >
        <q-menu max-height="190px" content-class="overflow-auto">
          <q-list style="min-width: 100px">
            <template v-for="bookmark in menuBookamrks">
              <q-item
                :key="bookmark.id"
                clickable
                @click="openBookmarkURL(bookmark.link)"
                style="min-height: 24px"
                v-close-popup
              >
                <q-item-section side top class="q-pr-sm" v-if="bookmark.link">
                  <img
                    :src="
                      'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&url=' +
                      bookmark.link +
                      '&size=32'
                    "
                    style="width: 20px"
                  />
                </q-item-section>
                <q-item-section>
                  {{ bookmark.title }}
                </q-item-section>
              </q-item>
            </template>
            <template v-for="bookmarkFolder in menuBookmarkFolders">
              <q-item
                :key="bookmarkFolder.id"
                clickable
                style="min-height: 24px"
              >
                <q-item-section side top class="q-pr-sm">
                  <q-icon
                    class="bookmark-button"
                    :name="$icons.mdiFolderOpen"
                  />
                </q-item-section>
                <q-item-section>
                  {{ bookmarkFolder.title }}
                </q-item-section>
                <q-menu :offset="[-150, -50]">
                  <q-list>
                    <template v-for="bookmark in bookmarkFolder.bookmarks">
                      <q-item
                        :key="bookmark.id"
                        clickable
                        @click="openBookmarkURL(bookmark.link)"
                        style="min-height: 24px"
                        v-close-popup
                      >
                        <q-item-section
                          side
                          top
                          class="q-pr-sm"
                          v-if="bookmark.link"
                        >
                          <img
                            :src="
                              'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&url=' +
                              bookmark.link +
                              '&size=32'
                            "
                            style="width: 20px"
                          />
                        </q-item-section>
                        <q-item-section>
                          {{ bookmark.title }}
                        </q-item-section>
                      </q-item>
                    </template>
                    <template>
                      <q-item
                        clickable
                        @click="
                          createBookmark.flag = true;
                          createBookmark.folder_id = bookmarkFolder.id;
                        "
                        v-close-popup
                      >
                        <q-item-section side top class="q-pr-xs">
                          <q-icon :name="$icons.matAdd"> </q-icon>
                        </q-item-section>
                        <q-item-section>Add bookmark</q-item-section>
                      </q-item>
                    </template>
                  </q-list>
                </q-menu>
                <q-menu
                  touch-position
                  context-menu
                  content-class="bookmark-options"
                >
                  <q-list dense>
                    <q-item
                      clickable
                      v-close-popup
                      @click="editBookmarkFolder(bookmarkFolder)"
                    >
                      <q-item-section>Edit</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      @click="deleteBookmarkFolderOpen(bookmarkFolder)"
                    >
                      <q-item-section>Delete</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      @click="
                        createBookmark.flag = true;
                        createBookmark.folder_id = bookmarkFolder.id;
                      "
                    >
                      <q-item-section>Add bookmark</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item>
            </template>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <confirm-dialog
      v-model="deleteConfirm.flag"
      title="Remove pinned message?"
      question="Are you sure you want to remove this pinned message?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => deletePinMessageDialogHandler(false)"
      :successMethod="deletePinMessage"
      :loading="false"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspace"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="labels"
      :folderList="folderList"
      :authToken="currentCompany.accessToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <new-task-dialog
      v-if="addTaskSection"
      v-model="addTaskSection"
      :closeDialog="closeNewTaskDialog"
      :workspace="currentWorkspace"
      :taskLabels="labels"
      :userId="user.id"
      :showWorkspaceSection="false"
      :sequencePosition="'bottom'"
      :taskObjFromMsg="taskObjFromMsg"
    />
    <create-bookmark-dialog
      v-if="createBookmark.flag"
      v-model="createBookmark.flag"
      :folderId="createBookmark.folder_id"
      :bookmark="createBookmark.bookmark"
      :user="user"
      :workspace="currentWorkspace"
      :handleResize="handleResize"
      @closeDialog="closeCreateBookmark"
    />
    <create-bookmark-folder-dialog
      v-if="createBookmarkFolder.flag"
      v-model="createBookmarkFolder.flag"
      :user="user"
      :workspace="currentWorkspace"
      :bookmarkFolder="createBookmarkFolder.bookmarkFolder"
      :handleResize="handleResize"
      @closeDialog="closeCreateBookmarkFolder"
    />
    <confirm-dialog
      v-if="deleteBookmark.flag"
      v-model="deleteBookmark.flag"
      title="Delete bookmark?"
      question="Are you sure you want to delete this bookmark?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteBookmarkDialogHandler(false)"
      :successMethod="
        () => deleteBookmarkDialogHandler(true, deleteBookmark.bookmark)
      "
      :loading="deleteBookmark.loader"
    />
    <confirm-dialog
      v-if="deleteBookmarkFolder.flag"
      v-model="deleteBookmarkFolder.flag"
      title="Delete bookmark folder?"
      question="Are you sure you want to delete this bookmark folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteBookmarkFolderDialogHandler(false)"
      :successMethod="() => deleteBookmarkFolderDialogHandler(true)"
      :loading="deleteBookmarkFolder.loader"
    />
    <document-editor-dialog
      v-if="documentDialog"
      v-model="documentDialog"
      :openDocEditor="openDocEditor"
      :closeDocEditor="closeDocEditor"
      :documentProp="createdDoc"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
    />
  </div>
</template>
<script>
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import CreateBookmarkDialog from "@/components/Dialogs/CreateBookmarkDialog";
import CreateBookmarkFolderDialog from "@/components/Dialogs/CreateBookmarkFolderDialog";
import PostMedia from "@/components/Miscellaneous/PostMedia";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import NewTaskDialog from "@/views/MyWorkView/NewTaskDialog";
import MessageReactionDialog from "@/components/MessageReactionDialog";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";

import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import getUnixTime from "date-fns/getUnixTime";

import { compile } from "@/components/Editor/MarkupUtils";
import NotificationNavMixin from "@/mixins/NotificationNavMixin";
import UserScope from "@/mixins/UserScope";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

import mixpanel from "@/mixpanel";

import { mapGetters } from "vuex";
import get from "lodash/get";
import Avatar from 'vue-avatar';

import {
  RemoveMessagePinMutation,
  DetachMessageFolderMutation,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
  AddMessageBookmarkMutation,
  RemoveMessageBookmarkMutation,
  AddMessageReactionMutation,
  RemoveMessageReactionMutation,
  DeleteBookmarkMutation,
  DeleteBookmarkFolderMutation,
} from "@/gql";

export default {
  name: "BookmarkToolbar",
  props: ["pinPost", "labels", "bookmarks", "bookmarkFolders"],
  mixins: [UserScope, NotificationNavMixin, DocumentEditorMixin],
  api: {
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspace}`;
      },
    },
  },
  components: {
    ConfirmDialog,
    PostMedia,
    FolderDetailsDialog,
    NewTaskDialog,
    MessageReactionDialog,
    CreateBookmarkDialog,
    CreateBookmarkFolderDialog,
    DocumentEditorDialog,
    Avatar,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    setTimeout(() => {
      this.bookmarksFiltered = this.bookmarks;
      this.bookmarkFoldersFiltered = this.bookmarkFolders;
      this.handleResize();
    }, 4000);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      showTooltip: true,
      deleteBookmark: {
        flag: false,
        loader: false,
        bookmark: null,
        folder_id: null,
      },
      deleteBookmarkFolder: {
        flag: false,
        loader: false,
        bookmarkFolder: null,
      },
      createBookmark: {
        flag: false,
        folder_id: null,
        bookmark: null,
      },
      createBookmarkFolder: {
        flag: false,
        bookmarkFolder: null,
      },
      addTaskSection: null,
      taskObjFromMsg: null,
      deleteConfirm: {
        flag: false,
      },
      showPinPost: false,
      post: null,
      folderDetailsDialog: {
        flag: false,
      },
      deleteFolder: {
        id: null,
        post: null,
        flag: false,
      },
      copyMoveDialog: {
        data: [],
        post: null,
        isFolder: false,
        isRoot: true,
        flag: false,
        loading: false,
      },
      delete_media: {
        id: null,
        post: null,
        flag: null,
      },
      bookmarksFiltered: [],
      bookmarkFoldersFiltered: [],
      menuBookamrks: [],
      menuBookmarkFolders: [],
    };
  },
  methods: {
    changeBookmarksFiltered() {
      this.bookmarksFiltered = this.bookmarks;
    },
    handleResize() {
      if (window.innerWidth < 1124 && this.bookmarks.length > 5) {
        this.bookmarksFiltered = this.bookmarks.slice(0, 6);
        this.menuBookamrks = this.bookmarks.slice(6, this.bookmarks.length);
      } else if (window.innerWidth < 1300 && this.bookmarks.length > 7) {
        this.bookmarksFiltered = this.bookmarks.slice(0, 7);
        this.menuBookamrks = this.bookmarks.slice(7, this.bookmarks.length);
      } else if (
        window.innerWidth > 1300 &&
        window.innerWidth < 1500 &&
        this.bookmarks.length > 9
      ) {
        this.bookmarksFiltered = this.bookmarks.slice(0, 9);
        this.menuBookamrks = this.bookmarks.slice(9, this.bookmarks.length);
      } else if (
        window.innerWidth > 1500 &&
        window.innerWidth < 1800 &&
        this.bookmarks.length > 11
      ) {
        this.bookmarksFiltered = this.bookmarks.slice(0, 11);
        this.menuBookamrks = this.bookmarks.slice(11, this.bookmarks.length);
      } else if (
        window.innerWidth > 1800 &&
        window.innerWidth < 2200 &&
        this.bookmarks.length > 15
      ) {
        this.bookmarksFiltered = this.bookmarks.slice(0, 11);
        this.menuBookamrks = this.bookmarks.slice(11, this.bookmarks.length);
      } else {
        this.bookmarksFiltered = this.bookmarks;
        this.menuBookamrks = [];
      }

      if (this.bookmarksFiltered.length !== this.bookmarks.length) {
        this.bookmarkFoldersFiltered = [];
        this.menuBookmarkFolders = this.bookmarkFolders;
      } else {
        this.bookmarkFoldersFiltered = this.bookmarkFolders;
        this.menuBookmarkFolders = [];
      }
    },
    removeMedia(postId, mediaId) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    async deleteBookmarkDialogHandler(flag = false, bookmarkData = null) {
      if (flag) {
        this.deleteBookmark.loader = true;
        try {
          const variables = {
            id: this.deleteBookmark.bookmark.id,
          };
          await this.$api.mutate({
            mutation: DeleteBookmarkMutation,
            variables,
          });
          if (this.deleteBookmark.folder_id) {
            const bookmarkFolder = this.$api.getEntity(
              "bookmarkFolder",
              this.deleteBookmark.folder_id
            );

            if (bookmarkFolder) {
              bookmarkFolder.bookmarks = bookmarkFolder.bookmarks.filter(
                (bookmark) => bookmark.id !== bookmarkData.id
              );

              this.bookmarkFoldersFiltered.bookmarks = this.bookmarkFoldersFiltered.bookmarks.filter(
                (bookmark) => bookmark.id !== bookmarkData.id
              );
            }
          } else {
            //Remove from Entity cache
            const query = this.$api.getQuery(
              `BookmarksQuery:${this.currentWorkspace.id}`
            );

            query.data.bookmarks = query.data.bookmarks.filter(
              (bookmark) => bookmark.id !== bookmarkData.id
            );
            this.changeBookmarksFiltered();
          }
          this.handleResize();
        } catch (error) {
          console.error(
            "ERROR: BookmarkToolbar -> deleteBookmarkDialogHandler",
            error
          );
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteBookmark.bookmark = null;
      this.deleteBookmark.folder_id = null;
      this.deleteBookmark.flag = false;
      this.deleteBookmark.loader = false;
    },
    deleteBookmarkOpen(bookmark, bookmarkFolderId = null) {
      this.deleteBookmark.flag = true;
      this.deleteBookmark.bookmark = bookmark;
      this.deleteBookmark.folder_id = bookmarkFolderId;
    },
    editBookmark(bookmark, bookmarkFolderId = null) {
      this.createBookmark.bookmark = bookmark;
      this.createBookmark.folder_id = bookmarkFolderId;
      this.createBookmark.flag = true;
      this.createBookmark.flag = true;
    },
    editBookmarkFolder(bookmarkFolder) {
      this.createBookmarkFolder.bookmarkFolder = bookmarkFolder;
      this.createBookmarkFolder.flag = true;
    },
    deleteBookmarkFolderOpen(bookmarkFolder) {
      this.deleteBookmarkFolder.flag = true;
      this.deleteBookmarkFolder.bookmarkFolder = bookmarkFolder;
    },
    async deleteBookmarkFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteBookmarkFolder.loader = true;
        try {
          const variables = {
            id: this.deleteBookmarkFolder.bookmarkFolder.id,
          };
          await this.$api.mutate({
            mutation: DeleteBookmarkFolderMutation,
            variables,
          });
          //Remove from Entity cache
          const query = this.$api.getQuery(
            `BookmarkFoldersQuery:${this.currentWorkspace.id}`
          );
          console.log("query", query);

          query.data.bookmarkFolders = query.data.bookmarkFolders.filter(
            (bookmarkFolder) =>
              bookmarkFolder.id !== this.deleteBookmarkFolder.bookmarkFolder.id
          );

          this.bookmarkFoldersFiltered = this.bookmarkFoldersFiltered.filter(
            (bookmark) => bookmark.id !== this.deleteBookmarkFolder.bookmarkFolder.id
          );

        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteBookmarkFolder.bookmarkFolder = null;
      this.deleteBookmarkFolder.flag = false;
      this.deleteBookmarkFolder.loader = false;
    },
    openBookmarkURL(url) {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) {
        window.electron.openExternal(url);
      } else {
        window.open(url, "_blank");
      }
    },
    closeCreateBookmark() {
      this.createBookmark.flag = false;
      this.createBookmark.folder_id = null;
      this.createBookmark.bookmark = null;
    },
    closeCreateBookmarkFolder() {
      this.createBookmarkFolder.flag = false;
      this.createBookmarkFolder.bookmarkFolder = null;
    },
    async reactOnMessage(args) {
      let { post, reaction } = args;
      if (typeof reaction !== "object") {
        reaction = this.reactions.find((o) => o.name === reaction);
      }

      const variables = {
        id: post.id,
        reaction_id: reaction.id,
      };
      const newReactiondata = { ...post.reactiondata };
      if (newReactiondata.hasOwnProperty(reaction.name)) {
        if (
          newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
        ) {
          if (newReactiondata[reaction.name].length > 1) {
            let index = newReactiondata[reaction.name].findIndex(
              (a) => a.id === this.user.id
            );
            newReactiondata[reaction.name].splice(index, 1);
          } else {
            delete newReactiondata[reaction.name];
          }
          await this.$api.mutate({
            mutation: RemoveMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        } else {
          newReactiondata[reaction.name].push({
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            pic: this.user.pic,
            time: getUnixTime(new Date()),
          });
          await this.$api.mutate({
            mutation: AddMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
          mixpanel.track("Message Reaction", { name: reaction.name });
        }
        const query = this.$api.getQuery("NotificationsQuery");
        if (query.data) {
          query.data.notifications.map((n) => {
            if (n.action.object_id === post.id) {
              n.action.object.reactiondata = {
                ...newReactiondata,
              };
            }
          });
        }
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
      } else {
        newReactiondata[reaction.name] = [
          {
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            pic: this.user.pic,
            time: getUnixTime(new Date()),
          },
        ];
        const query = this.$api.getQuery("NotificationsQuery");
        if (query.data) {
          query.data.notifications.map((n) => {
            if (n.action.object_id === post.id) {
              n.action.object.reactiondata = {
                ...newReactiondata,
              };
            }
          });
        }

        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
        await this.$api.mutate({
          mutation: AddMessageReactionMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Message Reaction", { name: reaction.name });
      }
    },
    async addMessageBookmark(post) {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Save tasks, messages and files across workspaces in your private saved items tab.";
        this.showCreateTask = true;
      } else {
        try {
          const variables = {
            post_id: post.id,
          };
          const postData = this.$api.getEntity("post", post.id);
          postData.isbookmarked = !post.isbookmarked;
          const workspaceEntity = this.$api.getEntity(
            "workspace",
            this.currentWorkspace.id
          );
          workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
          await this.$api.mutate({
            mutation: AddMessageBookmarkMutation,
            variables,
            skipUpdate: true,
          });
          this.$mixpanelEvent("save-message", {});
        } catch (error) {
          const workspaceEntity = this.$api.getEntity(
            "workspace",
            this.currentWorkspace.id
          );
          workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
          throw new Error(error);
        }
      }
    },
    async removeMessageBookmark(post) {
      try {
        const variables = {
          post_id: post.id,
        };

        const postData = this.$api.getEntity("post", post.id);
        postData.isbookmarked = !post.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveMessageBookmarkMutation,
          variables,
          skipUpdate: true,
        });
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    closeNewTaskDialog() {
      this.addTaskSection = false;
      this.taskObjFromMsg = null;
    },
    createTaskFromMessage(task, postMedia, messageId, fileteredUsers) {
      this.addTaskSection = true;
      this.taskObjFromMsg = { task, postMedia, messageId, fileteredUsers };
    },
    createTaskFromMessageHandler(post) {
      let eligible = this.$checkEligiblity("task_from_message");
      if (!eligible) {
        return false;
      } else if (
        this.companies &&
        this.companies[this.activeCompany].status.subscriptionOver
      ) {
        this.planeDescription =
          "Create tasks from messages with a simple click.";
        this.showCreateTask = true;
      } else {
        let postContentArr = post.content.split(" ");
        let mentionArray = [];
        postContentArr.forEach((item) => {
          if (item.includes("@")) {
            item = item.substring(1);
            mentionArray.push(item);
          }
        });
        const task = {
          title: "",
          html: null,
          details: post.content,
          labels: [],
          card_id: null,
        };
        this.createTaskFromMessage(task, post.media, post.id, mentionArray);
        this.$mixpanelEvent("task-from-message-click", {});
      }
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    clickonmoreoption(item) {
      this.$emit("clickonmoreoption", item);
    },
    fromComputer(media_id) {
      this.mediaId = media_id;
      this.$refs.vueFileUploader.openFileDialog();
    },
    deleteCommentPoint(deletedata) {
      this.$emit("deleteCommentPoint", deletedata);
    },
    copyMoveFileFolderHandler(
      items,
      isFolder = false,
      isRoot = true,
      post = null
    ) {
      this.copyMoveDialog.flag = true;
      this.copyMoveDialog.data = items; // Array of folder or file items
      this.copyMoveDialog.isFolder = isFolder;
      this.copyMoveDialog.isRoot = isRoot;
      this.copyMoveDialog.post = post;
    },
    sendComment(data) {
      this.$emit("sendComment", data);
    },
    updatepositions(updateposition) {
      const positions = {
        id: updateposition.id,
        x: updateposition.x,
        y: updateposition.y,
        media_id: this.gridMediaId,
      };
      this.$emit("updatepositions", positions);
    },
    updateMediaCommentPointLabel(themedata) {
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    updateComment(changedata) {
      this.$emit("updateComment", changedata);
    },
    deleteMediaComment(commentData) {
      this.$emit("deleteMediaComment", commentData);
    },
    async deleteFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            folder: [this.deleteFolder.id],
            post_id: this.deleteFolder.post.id,
          };
          await this.$api.mutate({
            mutation: DetachMessageFolderMutation,
            variables,
          });
          //Remove from Entity cache
          const postEntity = this.$api.getEntity(
            "post",
            this.deleteFolder.post.id
          );
          postEntity.mediafolder = postEntity.mediafolder.filter(
            (f) => f.id !== this.deleteFolder.id
          );
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.post = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },
    async deleteFolderHandler(folderId, post) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.post = post;
      this.deleteFolder.flag = true;
    },
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    reorderMedia(media) {
      return orderBy(uniqBy(media, "id"), ["sequence"]);
    },
    deletePinMessageDialogHandler(flag = true, postData = null) {
      this.deleteConfirm.flag = flag;
      this.post = postData;
    },
    async deletePinMessage() {
      try {
        const variables = {
          post_id: this.post.id,
        };

        const postData = this.$api.getEntity("post", this.post.id);
        postData.ispinpost = !postData.ispinpost;

        const PinMessagesQ = this.$api.getQuery(
          `PinMessagesQuery:${this.currentWorkspace.id}`
        );

        if (PinMessagesQ.data.pinMessages) {
          PinMessagesQ.data.pinMessages = PinMessagesQ.data.pinMessages.filter(
            (pinMessages) => pinMessages.id !== this.post.id
          );
        }

        await this.$api.mutate({
          mutation: RemoveMessagePinMutation,
          variables,
          skipUpdate: true,
        });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Pin removed successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        throw new Error(error);
      }
      this.deletePinMessageDialogHandler(false);
    },
    PostHtml(content) {
      return compile(content);
    },
    forwardPost(content) {
      return compile(content);
    },
  },
  computed: {
    // bookmarkFoldersFiltered() {
    //   return this.bookmarkFolders;
    // },
    filteredBookmarks() {
      // this.changeBookmarksFiltered();
      return this.bookmarksFiltered;
    },
    sortedBookmarks() {
      return orderBy(this.bookmarks, ["asc"]);
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(this.mediaFolders, "id");
      } else {
        return [];
      }
    },
    hasMessageUnread() {
      if (
        this.currentWorkspace.read_cursor !== 0 &&
        this.currentWorkspace.read_cursor <= this.post.id &&
        this.user.id !== this.post.user.id
      ) {
        return true;
      }
      return false;
    },
    hasNotification() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.object_id === this.post.id);
        return notifications.length > 0;
      }
      return false;
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.pin-message-menu{
  background-color: #f4f5f9;
  background: #f4f5f9;
  .q-item{
    padding:15px;
    .pinned-item{
      padding:10px;
      border: 1px solid #f4f5f9;
      border-radius: 5px;
      background-color: #fff;
      .q-btn{
        top: 15px;
        right: 15px;
      }
    }
  }
  .q-item {
    background-color: #f4f5f9;
    .q-btn{
        color: #fff !important;
      }
    &:hover{
      .q-btn{
        color: #000 !important;
      }
      .bookmark{
        color: #e91e63 !important;
      }
    }
  }
}
.pin-button{
  .q-field--dense .q-field__control {
    .q-icon {
      margin-right: 5px;
    }
  }
}
.bookmark-options{
  .q-list--dense {
    .q-item{
      padding: 6px 16px;
    }
  }
}
.more-menu{
  color:#a4aeb8;
  &:hover{
    color:#007aff;
  }
}
</style>
