<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
    @keydown.esc="closeDialog"
  >
    <q-card
      class="column no-wrap task-timer"
      style="
        max-height: calc(100vh - 125px);
        width: 726px;
        max-width: 726px;
        border-radius: 15px;
      "
    >
      <q-card-section class="row col-shrink no-wrap q-pa-none task-dialog">
        <!-- LEFT SECTION -->
        <div class="column col-grow task-dialog-left-section relative-position">
          <div class="flex-1 flex-y q-pa-sm" style="width: 100%">
            <div style="width: 100%" class="row justify-between items-center">
              <div
                :class="[
                  isInvalid && 'error-indicator',
                  'flex task-title-wrapper q-mt-md q-ml-md row items-center q-pl-sm q-pr-sm',
                ]"
                style="
                  background-color: #f4f5f8;
                  border-radius: 0px;
                  width: 65%;
                  height: 45px;
                  overflow: hidden;
                  white-space: normal;
                "
              >
                <!-- <div class="task-ticket-number q-mr-xs">
                  T{{ workspace.title.substring(0, 2).toUpperCase() }}-{{ task.id }}
                </div> -->
                <div
                  v-if="!editorTitleFocused"
                  class="editor-prototype flex dont-break-out q-mb-sm task-title overflow-auto cursor-pointer"
                  @click="handleTitleEditorFocus"
                >
                  {{ taskData.title && taskData.title }}
                </div>
                <div style="width: 100%" v-else>
                  <q-input
                    outline
                    borderless
                    input-class="transparent task-title"
                    standout="bg-color"
                    class="col-grow q-pb-none"
                    placeholder="Task name"
                    v-model="taskData.title"
                    @input="updateTaskTitle"
                    @blur="updateTaskTitle"
                    @focus="handleTitleEditorFocus"
                    @keyup.enter="updateTaskTitle"
                    autofocus
                  />
                </div>
              </div>
              <div
                class="flex-1 q-mt-md"
                style="
                  width: 35%;
                  vertical-align: revert;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 45px;
                "
              >
              <q-btn
                  round
                  flat
                  dense
                  no-caps
                  size="14px"
                  padding="0"
                  style="color:#007AFF"
                  :icon="taskData.is_lock ? $icons.matLock : $icons.matLockOpen"
                  class="q-ml-xs"
                  @click="taskData.is_lock = !taskData.is_lock"
                >
                <q-tooltip>{{ taskData.is_lock ? 'Unlock task' : 'Lock task' }}</q-tooltip>
              </q-btn>
              <q-btn
                flat
                dense
                size="14px"
                round
                padding="0"
                style="color:#007AFF;"
                :icon="taskData.is_invisible ? $icons.matVisibilityOff : $icons.matVisibility"
                class="q-ml-xs"
                @click="taskData.is_invisible = !taskData.is_invisible"
              >
                <q-tooltip>{{ taskData.is_invisible ? 'Make task visible' : 'Make task invisible' }}</q-tooltip>
              </q-btn>
                <q-btn
                  :icon="$icons.matClose"
                  text-color="blue-grey-8"
                  round
                  flat
                  padding="0"
                  dense
                  class="q-ml-xs"
                  @click="closeDialog"
                />
              </div>
            </div>
            <!--  eslint-disable-next-line vue/valid-v-on -->
            <div id="scrollableTaskDialog" class="flex-1 overflow-auto q-mt-md">
              <div>
                <task-description-editor-dialog
                    :closeDocEditor="closeDocEditor"
                    @addDescription="addDescription"
                    :documentProp="taskData"
                />
                <!--Description -->
                <!-- <div
                  v-if="!editorFocused"
                  class="q-ml-md q-mr-md q-mt-sm an-16 editor-prototype task-description-new-editor dont-break-out overflow-auto cursor-pointer"
                  style="
                    max-height: 120px;
                    min-height: 90px;
                    overflow: auto;
                    border-radius: 0px;
                  "
                  v-html="
                    descriptionData && descriptionData !== '<span></span>'
                      ? descriptionData
                      : '<p>Type task description...</p>'
                  "
                  @click="handleEditorFocus"
                ></div>
                <div
                  v-else
                  class="q-ml-md q-mr-md q-mt-sm editor-prototype task-description-new-editor"
                  style="
                    min-height: 90px;
                    max-height: 120px;
                    overflow: auto;
                    border-radius: 0px;
                  "
                >
                  <editor
                    v-model="taskData.details"
                    :editor-style="{ height: '148px', background: '#FFFFFF' }"
                    @submit="updateTaskDescription"
                    @blur="updateTaskDescription"
                    @focus="handleEditorFocus"
                    :autofocus="true"
                    :maxHeight="125"
                    placeholder="Task description ..."
                    :showEditorMenu="false"
                    overflow="auto"
                  />
                </div> -->
                <div class="q-px-md row relative-position q-mt-sm">
                  <div class="flex-1 q-mr-sm">
                    <q-btn
                      :icon="$icons.fasPaperclip"
                      dense
                      flat
                      class="new-task-control-button"
                      :class="attachmentMenu && 'active'"
                      color="grey-10"
                      size="md"
                      no-caps
                      @click.stop
                      padding="0"
                      style="width: 100%"
                    >
                      <div style="display: flex">
                        <span class="button-content" style="margin-right: 15px"
                          >Add file</span
                        >
                        <div class="icon">
                          <q-icon
                            v-if="attachmentMenu"
                            :name="$icons.matKeyboardArrowUp"
                            size="xs"
                          />
                          <q-icon
                            v-else
                            :name="$icons.matKeyboardArrowDown"
                            size="xs"
                          />
                        </div>
                      </div>
                      <!-- <q-tooltip>Add attachments</q-tooltip> -->
                      <q-menu auto-close v-model="attachmentMenu">
                        <q-list>
                          <q-item @click="fromComputer" clickable>
                            <q-item-section
                              style="min-width: 35px"
                              class="q-pr-none"
                              avatar
                            >
                              <q-icon :name="$icons.matComputer" size="24px" />
                            </q-item-section>
                            <q-item-section> Your Computer </q-item-section>
                          </q-item>
                          <q-item @click="sharedFileDialog = true" clickable>
                            <q-item-section
                              style="min-width: 35px"
                              class="q-pr-none"
                              avatar
                            >
                              <q-icon :name="$icons.fasCopy" size="24px" />
                            </q-item-section>
                            <q-item-section> Shared Files </q-item-section>
                          </q-item>
                          <q-separator />
                          <q-item @click="dropBox(null, userId)" clickable>
                            <q-item-section
                              style="min-width: 35px"
                              class="q-pr-none"
                              avatar
                            >
                              <q-icon :name="$icons.fabDropbox" size="24px" />
                            </q-item-section>
                            <q-item-section> Dropbox </q-item-section>
                          </q-item>
                          <q-item @click="googleDrive(null, userId)" clickable>
                            <q-item-section
                              style="min-width: 35px"
                              class="q-pr-none"
                              avatar
                            >
                              <q-icon
                                :name="$icons.fabGoogleDrive"
                                size="24px"
                              />
                            </q-item-section>
                            <q-item-section> Google Drive </q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                  <div class="flex-1 q-mr-sm">
                    <q-btn
                      :icon="$icons.fasUserCircle"
                      dense
                      flat
                      class="new-task-control-button"
                      color="grey-10"
                      size="md"
                      no-caps
                      @click.stop
                      padding="0"
                      style="width: 100%"
                    >
                      <span class="button-content">Assign to</span>
                      <q-menu
                        content-class="overflow-auto assign-member-menu"
                        v-model="menu"
                        fit
                      >
                        <div class="sticky-header">
                          <div
                            class="close-btn cursor-pointer"
                            @click="menu = false"
                          >
                            <q-icon
                              :name="$icons.matClose"
                              class="close-icon"
                            />
                          </div>
                          <div class="assign-to q-py-sm">Assign to</div>
                          <div class="search-member">
                            <q-input
                              autofocus
                              ref="search"
                              v-model="search"
                              outlined
                              placeholder="Search Member"
                              clearable
                              dense
                            >
                              <template v-slot:prepend>
                                <q-icon :name="$icons.matSearch" />
                              </template>
                            </q-input>
                          </div>
                        </div>
                        <q-list class="q-py-sm member-list">
                          <template v-for="user in workspaceAvailableUsers">
                            <q-item
                              class="q-px-none q-py-xs member-item items-center"
                              :key="user.id"
                              @click="() => assignMember(user)"
                              clickable
                            >
                              <q-item-section
                                class="member-avatar q-item-ellipsis"
                                avatar
                              >
                                <q-avatar size="32px" v-if="user.pic">
                                  <img :src="user.pic" :alt="user.first" />
                                </q-avatar>
                                <!-- <q-avatar
                                  size="32px"
                                  color="grey-4"
                                  text-color="grey-10"
                                  v-else
                                >
                                  {{ user.first | firstChar
                                  }}{{ user.last | firstChar }}
                                </q-avatar> -->
                                <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
                              </q-item-section>
                              <q-item-section class="member-name" v-tooltip>
                                {{ user.first | capitalize }}
                                {{ user.last | capitalize }}
                                <q-tooltip>
                                  {{ user.first | capitalize }}
                                  {{ user.last | capitalize }}
                                </q-tooltip>
                              </q-item-section>
                            </q-item>
                          </template>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                  <div class="flex-1 q-mr-sm">
                    <q-btn
                      v-if="taskData.calllink"
                      :icon="$icons.matVideoCall"
                      dense
                      flat
                      class="new-task-control-button"
                      color="grey-10"
                      size="md"
                      no-caps
                      @click.stop="removeCallLink"
                      padding="0"
                      style="width: 100%"
                    >
                      <span class="button-content">Remove call link</span>
                    </q-btn>
                    <q-btn
                      v-else
                      :icon="$icons.matVideoCall"
                      dense
                      flat
                      class="new-task-control-button"
                      color="grey-10"
                      size="md"
                      no-caps
                      @click.stop="createCallLink"
                      padding="0"
                      style="width: 100%"
                    >
                      <span class="button-content">Add call link</span>
                    </q-btn>
                  </div>
                  <div class="flex-1 q-mr-sm">
                    <q-btn
                      :icon="$icons.matDateRange"
                      dense
                      flat
                      class="new-task-control-button"
                      color="grey-10"
                      size="md"
                      no-caps
                      padding="0"
                      @click.stop="datePickermenu = !datePickermenu"
                      style="width: 100%"
                    >
                      <span class="button-content">Set due date</span>
                      <div>
                        <q-menu
                          :value="datePickermenu"
                          v-model="datePickermenu"
                          max-height="auto"
                          content-class="overflow-auto task-date-picker date-picker-calendar"
                        >
                          <q-date
                            mask="YYYY-MM-DD"
                            :value="dates"
                            today-btn
                            :options="optionsFn"
                            range
                            @input="updatedDate"
                            color="blue-13"
                          >
                            <div
                              class="date-time-block q-px-md q-py-sm column no-flex-wrap justify-between"
                            >
                              <!-- Start Date and Time row -->
                              <div
                                class="row justify-between items-center flex-no-wrap"
                              >
                                <div class="date-label">
                                  <span class="demi-bold"> Starts: </span>
                                  <span class="an-14 demi-bold-text">
                                    {{
                                      startDate
                                        ? startDate.substring(0, 10)
                                        : "None"
                                    }}
                                  </span>
                                </div>
                                <div class="time-input">
                                  <q-input
                                    :value="startTime"
                                    @input="(val) => (startTime = val)"
                                    @blur="checkTimeValidation"
                                    dense
                                    type="time"
                                    bg-color="white"
                                  >
                                    <div
                                      @click="startTimeMenu = !startTimeMenu"
                                      class="arrow-icon row items-center no-flex-wrap"
                                    >
                                      <q-icon
                                        v-if="startTimeMenu"
                                        :name="$icons.matKeyboardArrowUp"
                                      />
                                      <q-icon
                                        v-else
                                        :name="$icons.matKeyboardArrowDown"
                                      />
                                    </div>
                                    <q-menu
                                      no-parent-event
                                      content-class="time-option-wrapper overflow-auto"
                                      max-height="180px"
                                      v-model="startTimeMenu"
                                    >
                                      <q-list class="time-list">
                                        <template
                                          v-for="(
                                            time, index
                                          ) in startTimeDropdownOptions"
                                        >
                                          <q-item
                                            :key="index"
                                            @click.stop="setStartTime(time)"
                                            clickable
                                          >
                                            <q-item-section>
                                              {{ time.label }}
                                            </q-item-section>
                                          </q-item>
                                        </template>
                                      </q-list>
                                    </q-menu>
                                  </q-input>
                                </div>
                              </div>
                              <!-- End Date and Time row -->
                              <div
                                class="row justify-between items-center flex-no-wrap"
                              >
                                <div class="date-label">
                                  <span class="demi-bold"> Ends: </span>
                                  <span class="an-14 demi-bold-text">
                                    {{
                                      endDate
                                        ? endDate.substring(0, 10)
                                        : "None"
                                    }}
                                  </span>
                                </div>
                                <div class="time-input">
                                  <q-input
                                    :value="endTime"
                                    @input="(val) => (endTime = val)"
                                    @blur="checkTimeValidation"
                                    dense
                                    type="time"
                                    bg-color="white"
                                  >
                                    <div
                                      @click="endTimeMenu = !endTimeMenu"
                                      class="arrow-icon row items-center no-flex-wrap"
                                    >
                                      <q-icon
                                        v-if="endTimeMenu"
                                        :name="$icons.matKeyboardArrowUp"
                                      />
                                      <q-icon
                                        v-else
                                        :name="$icons.matKeyboardArrowDown"
                                      />
                                    </div>
                                    <q-menu
                                      no-parent-event
                                      content-class="time-option-wrapper overflow-auto"
                                      max-height="180px"
                                      v-model="endTimeMenu"
                                    >
                                      <q-list class="time-list">
                                        <template
                                          v-for="(
                                            time, index
                                          ) in endTimeDropdownOptions"
                                        >
                                          <q-item
                                            :key="index"
                                            @click.stop="setEndTime(time)"
                                            clickable
                                          >
                                            <q-item-section>
                                              {{ time.label }}
                                            </q-item-section>
                                          </q-item>
                                        </template>
                                      </q-list>
                                    </q-menu>
                                  </q-input>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row items-center justify-between btn-section"
                            >
                              <q-btn
                                flat
                                class="btn-style clear"
                                @click="save(null)"
                                label="Clear"
                                no-caps
                              />
                              <q-btn
                                flat
                                color="black"
                                class="btn-style"
                                @click="menu = false"
                                label="Cancel"
                                no-caps
                              />
                              <q-btn
                                flat
                                padding="xs md"
                                class="bg-primary text-white btn-style"
                                @click="save({ from: startDate, to: endDate })"
                                label="OK"
                              />
                            </div>
                          </q-date>
                        </q-menu>
                      </div>
                    </q-btn>
                  </div>
                  <div class="flex-1">
                    <q-btn
                      :icon="$icons.fasCheck"
                      dense
                      flat
                      class="new-task-control-button"
                      color="grey-10"
                      size="md"
                      no-caps
                      @click.stop
                      padding="0"
                      @click="addSubtaskAbove"
                      style="width: 100%"
                    >
                      <span class="button-content">New subtask</span>
                    </q-btn>
                  </div>
                </div>
                <div
                  v-if="(totalFiles.length + totalFolder.length > 0) || progress > 0"
                  class="q-px-md attachment-wrapper relative-position"
                  style="border-bottom: 1px solid #e3e8ed"
                >
                  <q-expansion-item
                    class="rounded-borders bg-white hide-expansion-hover"
                    header-class="q-px-none justify-between"
                    v-model="mediaExpansion"
                    default-opened
                  >
                    <template v-slot:header>
                      <div class="q-py-sm flex-1">
                        <div class="row no-mrpd items-center">
                          <div class="attachment-title q-mr-md">
                            Attachments
                            {{ totalFiles.length + totalFolder.length }}
                          </div>
                        </div>
                      </div>
                    </template>
                    <q-list class="q-pb-xs">
                      <div
                        style="display: flex; justify-content: center"
                        class="q-mb-sm"
                        v-if="progress > 0"
                      >
                        <q-linear-progress
                          color="green"
                          size="20px"
                          class="ma-0 progress"
                          dark
                          rounded
                          :value="progress"
                        >
                          <div
                            class="absolute-full flex label"
                            style="margin-left: 10px"
                          >
                            <div>
                              <p>
                                {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile - 1} files uploaded` : 'Uploaded'}}
                                <span>{{
                                  Math.round(progress * 100) + "%"
                                }}</span>
                              </p>
                            </div>
                          </div>
                        </q-linear-progress>
                      </div>
                      <q-list class="q-pb-xs">
                        <div
                          style="max-height: 258px"
                          class="overflow-auto q-pt-sm"
                        >
                          <template v-for="folder in folderList">
                            <my-work-task-folder-attachments
                              v-if="folder"
                              :key="folder.id"
                              :folder="folder"
                              :openFolder="openFolder"
                              :isWorkspaceOwner="isWorkspaceOwner"
                              :detachFolderAttachmentHandler="
                                detachFolderAttachmentHandler
                              "
                            />
                          </template>
                          <div
                            v-sortable="{
                              onStart: onAttachmentDragStart,
                              onEnd: onAttachmentDragEnd,
                              onAdd: onAttachmentDragAddOrUpdate,
                              onUpdate: onAttachmentDragAddOrUpdate,
                              ghostClass: 'sortable-ghost',
                              emptyInsertThreshold: 100,
                            }"
                          >
                            <template v-for="attachment in totalFiles">
                              <div
                                v-if="attachment"
                                :key="attachment.id"
                                :data-id="attachment.id"
                              >
                                <my-work-task-attachment
                                  size="35px"
                                  :media="attachment"
                                  :fullview="fullview"
                                  :pdfView="pdfView"
                                  :videoPlayer="videoPlayer"
                                  :audioPlayer="audioPlayer"
                                  :detachTaskAttachmentHandler="
                                    detachTaskAttachmentHandler
                                  "
                                ></my-work-task-attachment>
                              </div>
                            </template>
                          </div>
                        </div>
                      </q-list>
                    </q-list>
                  </q-expansion-item>
                </div>
                <div
                  class="row q-py-sm"
                  style="border-bottom: 1px solid #e3e8ed"
                  v-if="assignedMembers.length > 0"
                >
                  <div class="items-center q-px-md q-py-sm">
                    <div style="font-size: 18px; font-weight: 600">
                      Assigned to
                    </div>
                  </div>
                  <!-- Assigned Member List -->
                  <div
                    v-if="assignedMembers.length"
                    :class="[
                      assignedMembers.length > 3 ? 'q-px-md q-py-sm' : '',
                      'list flex q-pr-md',
                    ]"
                  >
                    <div
                      class="item row items-center flex-no-wrap justify-between q-mr-sm"
                      v-for="(member, i) in assignedMembers"
                      :key="'taskMember' + i"
                    >
                      <div class="row flex-no-wrap items-center">
                        <q-avatar size="sm" class="user_pic">
                          <img v-if="member.pic" :src="member.pic" />
                          <!-- <span else style="margin-top: -1px; margin-left: -1px"
                            >{{ member.first | firstChar
                            }}{{ member.last | firstChar }}</span
                          > -->
                          <avatar v-else :size="24" :customStyle="{'font-size':'12px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                        </q-avatar>
                        <div style="margin-left: 10px">
                          {{ member.first + " " + member.last }}
                        </div>
                      </div>
                      <div
                        class="close"
                        @click="confirmBoxHandler(true, member.id)"
                      >
                        <q-icon :name="$icons.matClose" size="xs" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row q-py-sm"
                  style="border-bottom: 1px solid #e3e8ed"
                  v-if="taskData.calllink"
                >
                  <div class="items-center q-px-md q-py-sm">
                    <div style="font-size: 18px; font-weight: 600">
                      Call link
                    </div>
                  </div>
                  <!-- Assigned Member List -->
                  <div
                    class="list flex q-pr-md"
                    style="font-size: 15px; font-weight: 400"
                  >
                    <div class="row items-center flex-no-wrap justify-between">
                      <q-btn
                        :icon="$icons.matVideoCall"
                        dense
                        flat
                        class="new-task-control-button active"
                        color="grey-10"
                        size="md"
                        no-caps
                        @click.stop="removeCallLink"
                        style="width: 100%"
                        padding="2px"
                      >
                        <div style="display: flex">
                          <span class="button-content">Video meeting link</span>
                          <div class="icon" style="margin-left: 8px">
                            <q-icon :name="$icons.matClose" />
                          </div>
                        </div>
                      </q-btn>
                    </div>
                  </div>
                </div>
                <div
                  class="row q-py-sm"
                  style="border-bottom: 1px solid #e3e8ed"
                  v-if="this.finalDates"
                >
                  <div class="items-center q-px-md q-py-sm">
                    <div style="font-size: 18px; font-weight: 600">
                      Due date
                    </div>
                  </div>
                  <!-- Assigned Member List -->
                  <div
                    class="list flex q-pr-md"
                    style="font-size: 15px; font-weight: 400"
                  >
                    <div
                      class="item row items-center flex-no-wrap justify-between"
                    >
                      <span style="font-weight: 500">Starts:&nbsp;</span>
                      <span>{{ finalDates.from }}</span>
                    </div>
                  </div>
                  <div
                    class="list flex q-pr-md"
                    style="font-size: 15px; font-weight: 400"
                  >
                    <div
                      class="item row items-center flex-no-wrap justify-between"
                    >
                      <span style="font-weight: 500">End:&nbsp;</span>
                      <span>{{ finalDates.to }}</span>
                    </div>
                  </div>
                </div>
                <!-- Subtasks Section -->
                <div
                  class="subtasks-section q-pt-md"
                  v-if="
                    taskData &&
                    taskData.subtasks &&
                    taskData.subtasks.length > 0
                  "
                >
                  <div
                    class="row items-center q-px-md justify-between stick-at-top"
                  >
                    <div
                      class="text-center row justify-center"
                      style="align-items: center"
                    >
                      <div class="title q-mr-sm">
                        Sub-tasks: {{ taskData.subtasks.length }}
                      </div>
                      <q-btn
                        dense
                        flat
                        class="control-button"
                        color="grey-10"
                        size="md"
                        padding="0"
                        :style="{
                          height: '29px',
                          width: '60px',
                        }"
                        no-caps
                        @click="addSubtaskAbove"
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: start;
                            width: 100%;
                          "
                          class="q-pl-sm"
                        >
                          <span style="font-size: 12px; margin-left: 0px"
                            >Add</span
                          >
                        </div>
                        <q-tooltip>Create subtask</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                  <div class="q-mt-sm subtask-main-div">
                    <!-- <div v-for="subtask in subtasks" :key="subtask">
                      {{subtask.title}}
                    </div> -->
                    <subtask
                      v-for="(subtask, i) in subtasks"
                      :key="'subtask_' + i"
                      :subtask="subtask"
                      :currentWorkspace="currentWorkspace"
                      :fromNewTask="true"
                      :currentUser="user"
                      :workspaceMembers="workspaceMembers"
                      @updateSubtaskMod="
                        (args) => updateSubtaskModHandler(args, i)
                      "
                      @deleteSubtask="() => deleteSubtask(i)"
                      :canRemoveAttachment="canRemoveAttachment"
                      :isFromSubtask="true"
                      :task="taskData"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[newSubtaskEditorVisibility && 'q-py-sm q-px-md']">
            <subtask-editor
              ref="subtaskEditor"
              v-show="newSubtaskEditorVisibility"
              :subtask="editSubtaskContent ? editSubtaskContent : null"
              :resetSubtaskEditor="resetSubtaskEditor"
              :workspace="currentWorkspace"
              :taskLabels="taskLabels"
              :newSubtaskEditorVisibility="newSubtaskEditorVisibility"
              @visibleSubtaskAdder="visibleSubtaskAdder"
              @addSubtask="addSubtask"
              @updateMediaCache="(data) => $emit('updateMediaCache', data)"
              @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
              @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
              @updatepositions="(data) => $emit('updatepositions', data)"
              @updateMediaCommentPointLabel="
                (data) => $emit('updateMediaCommentPointLabel', data)
              "
              @updateComment="(data) => $emit('updateComment', data)"
              @sendComment="(data) => $emit('sendComment', data)"
              @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
              @closeMediaModel="(data) => $emit('closeMediaModel', data)"
              @updateMediaCacheWithReactivity="
                (data) => $emit('updateMediaCacheWithReactivity', data)
              "
            />
          </div>
          <div
            class="row justify-between q-ml-md q-mr-md q-mb-md"
            v-if="showWorkspaceSection"
          >
            <div class="flex">
              <div class="flex q-mt-md q-mr-md">
                <div class="text-bold q-mt-sm q-mb-sm" style="width: 100%">
                  Workspace
                </div>
                <div class="q-mt-sm" style="width: 100%">
                  <q-btn
                    :icon="$icons.fasBriefcase"
                    size="md"
                    class="dropdown-set mytask-button"
                    no-caps
                    style="
                      font-weight: 400;
                      border: 1px solid #dbecff;
                      background: #dbecff;
                      color: #007aff;
                      width: 100%;
                    "
                    :disabled="workspace"
                  >
                    <div
                      class="button-content"
                      style="width: 80%; display: flex; align-items: center"
                    >
                      <div
                        class="ellipsis"
                        style="width: 117px; text-align: left"
                      >
                        {{ selectedWorkspace && selectedWorkspace.title }}
                      </div>
                      <q-icon
                        style="margin-right: 0px"
                        :name="$icons.matKeyboardArrowDown"
                      />
                    </div>
                    <q-menu
                      content-class="overflow-auto assign-member-menu"
                      auto-close
                    >
                      <div class="sticky-header">
                        <div
                          class="close-btn cursor-pointer"
                          @click="menu = false"
                        >
                          <q-icon :name="$icons.matClose" class="close-icon" />
                        </div>
                        <div class="assign-to q-py-sm">Workspaces</div>
                        <div class="search-member">
                          <q-input
                            autofocus
                            ref="searchWorkspaces"
                            v-model="searchWorkspaces"
                            outlined
                            placeholder="Search Member"
                            clearable
                            dense
                          >
                            <template v-slot:prepend>
                              <q-icon :name="$icons.matSearch" />
                            </template>
                          </q-input>
                        </div>
                      </div>
                      <q-list
                        style="min-width: 140px"
                        class="q-py-sm member-list"
                      >
                        <q-item
                          @click="chooseWorkspace(workspace)"
                          v-for="workspace in filteredWorkspaces"
                          :key="workspace.id"
                          clickable
                          class="q-px-none q-py-xs member-item items-center"
                        >
                          <q-item-section class="member-name">
                            {{ workspace.title }}
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
              <div class="flex q-mt-md q-mr-md">
                <div class="text-bold q-mt-sm q-mb-sm" style="width: 100%">
                  Column
                </div>
                <div v-if="cardList" class="q-mt-sm" style="width: 100%">
                  <q-btn
                    :icon="$icons.matViewList"
                    size="md"
                    class="dropdown-set mytask-button"
                    no-caps
                    style="
                      font-weight: 400;
                      border: 1px solid #dbecff;
                      background: #dbecff;
                      color: #007aff;
                      width: 100%;
                    "
                  >
                    <div
                      class="button-content"
                      style="width: 80%; display: flex; align-items: center"
                    >
                      <div
                        class="ellipsis"
                        style="width: 117px; text-align: left"
                      >
                        {{ selectedCard && selectedCard.title }}
                      </div>
                      <q-icon
                        style="margin-right: 0px"
                        :name="$icons.matKeyboardArrowDown"
                      />
                    </div>
                    <q-menu content-class="overflow-auto" auto-close>
                      <q-list style="min-width: 140px">
                        <q-item
                          @click="chooseCard(card)"
                          v-for="card in cardList"
                          :key="card.id"
                          clickable
                        >
                          <q-item-section class="q-item-ellipsis">
                            {{ card.title }}
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
            </div>
            <!-- <div  class="q-mt-md">
              <div class="text-bold q-mt-sm q-mb-sm" style="width:100%">
                Assign to
              </div>
              <div v-if="selectedWorkspace" class="q-mt-sm">
                <q-btn
                  size="md"
                  class="dropdown-set"
                  no-caps
                  style="
                    font-weight: 400;
                    border: 1px solid #e3e8ed;
                    width: 175px;
                  "
                >
                  <div class="button-content">
                    <div
                      v-if="selectedUser"
                      class="ellipsis"
                      style="width: 117px; text-align: left"
                    >
                      {{ selectedUser.first + " " + selectedUser.last }}
                    </div>
                    <div v-else style="color: #bdc2c9">Select From list</div>
                    <q-icon :name="$icons.matKeyboardArrowDown" />
                  </div>
                  <q-menu
                    content-class="overflow-auto assign-member-menu"
                    v-model="menu"
                    fit
                  >
                    <div class="sticky-header">
                      <div
                        class="close-btn cursor-pointer"
                        @click="menu = false"
                      >
                        <q-icon :name="$icons.matClose" class="close-icon" />
                      </div>
                      <div class="assign-to q-py-sm">Assign to</div>
                      <div class="search-member">
                        <q-input
                          autofocus
                          ref="search"
                          v-model="search"
                          outlined
                          placeholder="Search Member"
                          clearable
                          dense
                        >
                          <template v-slot:prepend>
                            <q-icon :name="$icons.matSearch" />
                          </template>
                        </q-input>
                      </div>
                    </div>
                    <q-list class="q-py-sm member-list">
                      <template v-for="user in workspaceAvailableUsers">
                        <q-item
                          class="q-px-none q-py-xs member-item items-center"
                          :key="user.id"
                          @click="
                            selectedUser = user;
                            menu = false;
                          "
                          clickable
                        >
                          <q-item-section
                            class="member-avatar q-item-ellipsis"
                            avatar
                          >
                            <q-avatar size="32px" v-if="user.pic">
                              <img :src="user.pic" :alt="user.first" />
                            </q-avatar>
                            <q-avatar
                              size="32px"
                              color="grey-4"
                              text-color="grey-10"
                              v-else
                            >
                              {{ user.first | firstChar
                              }}{{ user.last | firstChar }}
                            </q-avatar>
                          </q-item-section>
                          <q-item-section class="member-name" v-tooltip>
                            {{ user.first | capitalize }}
                            {{ user.last | capitalize }}
                            <q-tooltip>
                              {{ user.first | capitalize }}
                              {{ user.last | capitalize }}
                            </q-tooltip>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-list>
                  </q-menu>
                </q-btn>
              </div>
            </div> -->
            <div class="flex-1" style="display: flex; align-items: flex-end">
              <div class="">
                <q-btn
                  dense
                  no-caps
                  flat
                  size="md"
                  style="
                    background-color: #007aff;
                    color: white;
                    font-weight: 400;
                    padding: 2px 10px 2px 10px;
                  "
                  class="q-px-md q-ml-none full-width"
                  label="Create Task"
                  @click="createTaskHandler"
                  :loading="isLoading"
                  :disabled="isInvalid || isLoading"
                  outline
                />
              </div>
            </div>
          </div>
          <div class="row justify-end q-ml-md q-mr-md q-mb-md" v-else>
            <div class="q-mt-md q-mr-md">
              <div class="text-bold q-mt-sm q-mb-sm" style="width: 100%">
                Column
              </div>
              <div v-if="cardList" class="q-mt-sm">
                <q-btn
                  :icon="$icons.matViewList"
                  size="md"
                  class="dropdown-set"
                  no-caps
                  style="
                    font-weight: 400;
                    border: 1px solid #dbecff;
                    background: #dbecff;
                    color: #007aff;
                  "
                >
                  <div
                    class="button-content q-ml-xs"
                    style="display: flex; align-items: center"
                  >
                    <div
                      class="ellipsis"
                      style="width: 117px; text-align: left"
                    >
                      {{ selectedCard && selectedCard.title }}
                    </div>
                    <q-icon style="font-size: 16px;" :name="$icons.matKeyboardArrowDown" />
                  </div>
                  <q-menu content-class="overflow-auto" auto-close>
                    <q-list style="min-width: 140px">
                      <q-item
                        @click="chooseCard(card)"
                        v-for="card in cardList"
                        :key="card.id"
                        clickable
                      >
                        <q-item-section class="q-item-ellipsis">
                          {{ card.title }}
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </div>
            </div>
            <div
              class="flex1"
              style="
                display: flex;
                align-items: flex-end;
                justify-content: center;
              "
            >
              <div class="">
                <q-btn
                  dense
                  no-caps
                  flat
                  size="md"
                  style="
                    background-color: #007aff;
                    color: white;
                    font-weight: 400;
                    padding: 2px 16px 2px 16px;
                  "
                  class="q-px-md q-ml-none full-width"
                  label="Create Task"
                  @click="createTaskHandler"
                  :loading="isLoading"
                  :disabled="isInvalid || isLoading"
                  outline
                />
              </div>
            </div>
          </div>
          <!-- <div v-else>
            <q-separator style="background:#E3E8ED" />
            <div class="q-py-md q-pr-md text-right create-task-from-message">
              <q-btn
                dense
                label="Cancel"
                padding="0"
                no-caps
                class="create-task-btn"
                style="margin-right: 10px"
                @click="closeDialog"
                v-close-popup
              />
              <q-btn
                dense
                label="Create Task"
                @click="createTaskHandler"
                :loading="isLoading"
                :disabled="isInvalid"
                color="primary"
                text-color="white"
                padding="0"
                no-caps
                class="create-task-btn"
                v-close-popup
              />
            </div>
          </div> -->
        </div>
      </q-card-section>
    </q-card>

    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="currentWorkspace && currentWorkspace.id"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
      @setProgress="setProgress"
      @updateUploadCount="
        (total, uploaded) => {
          uploadCount = uploaded;
          totalFile = total;
        }
      "
    />
    <shared-files-dialog
      v-if="sharedFileDialog"
      v-model="sharedFileDialog"
      @closeDialog="sharedFileDialog = false"
      :attachMediaToPost="addSharedFileToUploader"
      :currentWorkspaceId="currentWorkspace.id"
    />
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="totalFiles"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="user"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :labels="taskLabels"
      :resetDialog="resetDialog"
      @clickonhidebutton="ishide = !ishide"
      @setActiveMedia="setActiveMedia"
      @closeMediaModel="closeMediaModel"
      @clickonmoreoption="clickonmoreoption"
      @deleteCommentPoint="deleteCommentPoint"
      @sendComment="sendComment"
      @updatepositions="updatepositions"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
      @deleteMediaComment="deleteMediaComment"
      @updateComment="updateComment"
    ></image-viewer-dialog>
    <pdf-view-dialog
      v-if="showpdfModel"
      v-model="showpdfModel"
      :fileExt="fileExt"
      @resetPdfDialog="showpdfModel = false"
      :pdfMediaData="pdfMediaData"
    />
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="totalFiles"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    />
    <confirm-dialog
      v-if="confirmRemoveMember.flag"
      v-model="confirmRemoveMember.flag"
      title="Remove Member?"
      question="Are you sure you want to remove this member?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmBoxHandler(false)"
      :successMethod="deleteTaskMember"
      :loading="false"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspace.id"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="taskLabels"
      :folderList="folderList"
      :authToken="authToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
  </q-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import filter from "lodash/filter";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

import format from "date-fns/format";
import subDays from "date-fns/subDays";
// import Editor from "@/components/Editor/Editor";
import UserScope from "@/mixins/UserScope";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload.vue";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { compile } from "@/components/Editor/MarkupUtils";
import RandomPosition from "random-position";
import Fuse from "fuse.js";

import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
//import uniq from "lodash/uniq";
import pick from "lodash/pick";
import head from "lodash/head";
import last from "lodash/last";
import map from "lodash/map";

import getUnixTime from "date-fns/getUnixTime";
import { isPast } from "date-fns";
import { parseISO } from "date-fns";
import { timeList } from "@/utils/Contents.js";

import Avatar from 'vue-avatar';
import {
  CreateDuplicateTaskMutation,
  CreateTaskLabelMutation,
  MyWorkQuery,
  DeleteTaskLabelMutation,
  GetNewMediaListMutation,
  UpdateMediaMutation,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
} from "@/gql";

import mixpanel from "@/mixpanel";
import SubtaskEditor from "@/components/Editor/SubtaskEditor";
import Subtask from "@/views/BoardView/Subtask.vue";
import MyWorkTaskAttachment from "@/views/MyWorkView/MyWorkTaskAttachment";
import MyWorkTaskFolderAttachments from "@/views/MyWorkView/MyWorkTaskFolderAttachments";
import { date } from "quasar";
import TaskDescriptionEditorDialog from "@/components/Dialogs/TaskDescriptionEditorDialog";

export default {
  name: "NewTaskDialog",
  mixins: [
    UserScope,
    MediaMethodMixin,
    GoogleDriveMethodMixin,
    DropBoxMixin,
    CopiedImageOnEditor,
    DocumentEditorMixin,
  ],
  props: [
    "value",
    "closeDialog",
    "taskLabels",
    "userId",
    "workspace",
    "showWorkspaceSection",
    "card",
    "sequencePosition",
    "taskObjFromMsg",
  ],
  filters: {
    dateConversion(postGroupDate) {
      if (postGroupDate === format(new Date(), "MMM. do, yyyy")) {
        return "Today";
      } else if (
        postGroupDate === format(subDays(new Date(), 1), "MMM. do, yyyy")
      ) {
        return "Yesterday";
      }
      return postGroupDate;
    },
  },
  components: {
    // Editor,
    SubtaskEditor,
    Subtask,
    VueDropzoneFileUpload,
    MyWorkTaskAttachment,
    PdfViewDialog,
    VideoDialog,
    AudioDialog,
    ImageViewerDialog,
    SharedFilesDialog,
    ConfirmDialog,
    MyWorkTaskFolderAttachments,
    FolderDetailsDialog,
    Avatar,
    TaskDescriptionEditorDialog,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    workspaceMembers: {
      defaultValue: [],
      cacheKey() {
        return `WorkspaceMembersQuery:${this.selectedWorkspace.id}`;
      },
    },
  },
  beforeCreate() {
    this.$eventBus.$off("fetchMoreMedia");
  },
  beforeDestroy() {
    document.body.classList.add("vabotu-body");
    this.$eventBus.$off("closeTaskDialog", this.closeTaskDialog);
    this.$eventBus.$off("fetchMoreMedia");
  },
  mounted() {
    this.dates = format(new Date(), "yyyy-MM-dd");
    this.selectedWorkspace = this.workspace
      ? this.workspace
      : this.generalWorkspace;
    this.cardList = this.selectedWorkspace.boards[0].cards;
    this.selectedCard = this.card ? this.card : this.cardList[0];
    if (this.taskObjFromMsg) {
      const { task, postMedia, fileteredUsers } = this.taskObjFromMsg;
      this.assignedMembers = [...fileteredUsers];
      this.taskData.details = task.details;
      if (postMedia.length) {
        this.addSharedFileToUploader(postMedia);
      }
    }
  },
  data() {
    return {
      finalDates: null,
      dates: null,
      startTimeMenu: false,
      endTimeMenu: false,
      startTime: "09:00",
      endTime: "17:00",
      datePickermenu: false,
      menu: false,
      attachmentMenu: false,
      search: null,
      selectedUser: null,
      isLoading: false,
      selectedWorkspace: null,
      selectedCard: null,
      selectedMember: null,
      cardList: null,
      editorFocused: false,
      editorTitleFocused: true,
      labelmenu: false,
      addTaskLabel: false,
      taskData: {
        id: null,
        completed: null,
        members: [],
        start_date: null,
        end_date: null,
        labels: [],
        title: "",
        details: "",
        subtasks: [],
        calllink: null,
        is_lock:false,
        is_invisible:false,
      },
      isInvalid: true,
      newSubtaskEditorVisibility: false,
      editSubtaskContent: null,
      editSubtask: {
        flag: false,
        index: 0,
      },
      searchWorkspaces: null,
      showTaskLabel: false,
      fileList: null,
      folderList: null,
      sharedFileDialog: false,
      progress: 0,
      totalFile: 0,
      uploadCount: 0,
      showVideoModel: false,
      showAudioModel: false,
      audioMediaData: null,
      displayPlayer: false,
      videoMediaData: null,
      dialogMedia: null,
      gridMediaId: "",
      showModal: false,
      pdfMediaData: null,
      showpdfModel: false,
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
        {
          title: "Hide Comments",
        },
      ],
      ishide: false,
      mediaExpansion: false,
      assignedMembers: [],
      confirmRemoveMember: {
        flag: false,
        id: null,
      },
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
    };
  },
  methods: {
    addDescription(data){
      this.taskData.details = data;
    },
    removeCallLink() {
      this.taskData.calllink = null;
    },
    createCallLink() {
      this.taskData.calllink = `https://${
        process.env.VUE_APP_MEET_URL
      }/${this.$generateString()}-${this.currentWorkspace.title
        .replace(/ /g, "-")
        .toLowerCase()}/${this.user.id}`;
    },
    checkTimeValidation() {
      if (
        Date.parse(this.startDate + " " + this.startTime) >
        Date.parse(this.endDate + " " + this.endTime)
      ) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Invalid time value",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.startTime = "09:00";
        this.endTime = "17:00";
      }
      this.isDisabled = false;
    },
    updatedDate(date) {
      if (date && date.type && date.type === "input") {
        date.preventDefault();
        return false;
      }
      if (date && date.from) {
        this.dates = date;
      } else {
        this.dates = date;
      }
    },
    optionsFn(d) {
      return d >= date.formatDate(Date.now(), "YYYY/MM/DD");
    },
    save(dates) {
      const days = {};
      if (dates) {
        this.taskData.start_date =
          this.startDate.split(" ")[0] + " " + this.startTime;
        this.taskData.end_date =
          this.endDate.split(" ")[0] + " " + this.endTime;
        days.from = format(
          new Date(this.startDate.split(" ")[0] + " " + this.startTime),
          "LLL dd, yyyy 'at' hh:mmaa"
        );
        days.to = format(
          new Date(this.endDate.split(" ")[0] + " " + this.endTime),
          "LLL dd, yyyy 'at' hh:mmaa"
        );
        this.finalDates = days;
      } else {
        this.dates = format(new Date(), "yyyy-MM-dd");
        days.from = null;
        days.to = null;
        this.taskData.start_date = null;
        this.taskData.end_date = null;
        this.startTime = "09:00";
        this.endTime = "17:00";
        this.finalDates = null;
      }

      if (Date.parse(days.from) > Date.parse(days.to)) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Invalid time value",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        return;
      }
      this.datePickermenu = false;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    openRenameFolderDialog(folder) {
      this.renameFolderDetails = folder;
      this.renameMediaFolderDialog.flag = true;
    },
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    assignMember(member) {
      let memberIds = this.assignedMembers.map((m) => m.id);
      if (memberIds.includes(member.id)) {
        this.assignedMembers = this.assignedMembers.filter(
          (m) => m.id !== member.id
        );
      } else {
        this.assignedMembers.push(member);
      }
      this.menu = false;
    },
    confirmBoxHandler(flag = true, id = null) {
      this.confirmRemoveMember = {
        flag,
        id,
      };
    },
    deleteTaskMember() {
      this.assignedMembers = this.assignedMembers.filter(
        (m) => m.id !== this.confirmRemoveMember.id
      );
      this.confirmRemoveMember.flag = false;
      this.confirmRemoveMember.id = false;
    },
    onAttachmentDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = this.totalFiles ? [...this.totalFiles] : [];
      if (event.from === event.to) tmp.splice(oldIndex, 1);
      tmp.splice(newIndex, 0, media);

      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );

      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
      //mixpanel.track("Task Move");
    },
    onAttachmentDragStart() {
      document.body.classList.add("dragging");
    },
    onAttachmentDragEnd() {
      document.body.classList.remove("dragging");
    },
    addSharedFileToUploader(files) {
      const sharedFiles = files.filter((f) => {
        if (f.hasOwnProperty("filename")) {
          return f;
        }
      });

      const sharedFolders = files.filter((f) => {
        if (f.hasOwnProperty("title")) {
          return f;
        }
      });

      if (sharedFiles.length) {
        let data;
        for (let media in sharedFiles) {
          const mediaEntity = this.$api.getEntity(
            "media",
            sharedFiles[media].id
          );
          mediaEntity.verifyKey = "fromShared";
          data = {};
          sharedFiles[media]["verifyKey"] = "fromShared";
          data[sharedFiles[media].id] = sharedFiles[media];
          const fileList = this.fileList;
          this.fileList = { ...fileList, ...data };
        }
      }

      if (sharedFolders.length) {
        let data;

        for (let folder in sharedFolders) {
          data = {};
          data[sharedFolders[folder].id] = sharedFolders[folder];
          const folderList = this.folderList;
          this.folderList = { ...folderList, ...data };
        }
      }
      this.sharedFileDialog = false;
    },
    detachTaskAttachmentHandler(mediaId) {
      const fileList = this.fileList;
      delete fileList[mediaId];
      this.fileList = { ...fileList };
    },
    detachFolderAttachmentHandler(folderId) {
      const folderList = this.folderList;
      delete folderList[folderId];
      this.folderList = { ...folderList };
    },
    pdfView(media) {
      this.pdfMediaData = media;
      this.showpdfModel = true;
    },
    resetPdfDialog() {
      setTimeout(() => {
        this.showpdfModel = false;
      }, 10);
    },
    videoPlayer(media) {
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    fullview(media) {
      this.dialogMedia = media;
      this.showModal = true;
      this.gridMediaId = media.id;
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
      this.mediaExpansion = true;
    },
    fileUploadCloseHandler(data) {
      const fileList = this.fileList;
      this.fileList = { ...fileList, ...data };
      const mediaIds = data ? map(data, (o) => o.id) : [];
      this.updateMediaCacheFromEditor(mediaIds);
    },
    async updateMediaCacheFromEditor(mediaIds) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });
      const query = this.$api.getQuery(
        `MediaQuery:${this.selectedWorkspace.id}`
      );
      if (query.data && query.data.newMedia.length) {
        query.data.newMedia.unshift(...response.data.getNewMediaList);
        // this.media.unshift(...response.data.getNewMediaList);
      } else {
        query.data = { newMedia: response.data.getNewMediaList };
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    closePlanExpiredDialog() {
      this.showTaskLabel = false;
    },
    openAddTaskLabel() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.showTaskLabel = true;
      } else {
        this.addTaskLabel = true;
      }
    },
    async deleteTaskLabel(id) {
      const variables = {
        id: id,
      };
      await this.$api.mutate({
        mutation: DeleteTaskLabelMutation,
        variables,
      });

      const taskLabelQuery = this.$api.getQuery(`TaskLabelsQuery`);

      taskLabelQuery.data.taskLabels = taskLabelQuery.data.taskLabels.filter(
        (c) => c.id !== id
      );
      mixpanel.track("Task Label Add");
    },
    onCloseAddingLabel() {
      setTimeout(() => {
        var vm = this;
        vm.addTaskLabel = false;
      }, 200);
    },
    async addTaskLabelEventMyHandler(data) {
      const variables = {
        name: data.name,
        colorcode: data.colorcode,
      };
      let newTask = await this.$api.mutate({
        mutation: CreateTaskLabelMutation,
        variables,
      });

      const taskLabelQuery = this.$api.getQuery(`TaskLabelsQuery`);
      taskLabelQuery.data.taskLabels.push(newTask.data.createTaskLabel);
      mixpanel.track("Task Label Add");
    },
    async toggleTaskLabel(label) {
      const newLabels = this.taskData.labels.filter(
        (item) => item.id !== label.id
      );

      if (this.selectedLabels.includes(label.id)) {
        this.taskData.labels = newLabels;
      } else {
        if (this.taskData.labels.length < 4) {
          if (newLabels.length === this.taskData.labels.length) {
            newLabels.push(label);
          }
          this.taskData.labels = newLabels;
        } else {
          this.$q.notify({
            position: "top-right",
            message: "You can set up to 4 labels",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            classes: "max-label-notification",
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
    },
    dateUpdateHandler(dates) {
      this.taskData.start_date = dates ? dates.from : null;
      this.taskData.end_date = dates ? dates.to : null;
    },
    chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
      this.cardList = workspace.boards[0].cards;
      this.selectedCard = this.cardList[0];
    },
    chooseCard(card) {
      this.selectedCard = card;
    },
    handleEditorFocus() {
      this.editorFocused = true;
    },
    handleTitleEditorFocus() {
      this.editorTitleFocused = true;
    },
    updateTaskDescription() {
      this.editorFocused = false;
    },
    updateTaskTitle() {
      if (!this.taskData.title || this.taskData.title.trim() === "") {
        this.isInvalid = true;
        return;
      }
      this.isInvalid = false;
      //this.editorTitleFocused = false;
    },
    createTaskHandler() {
      this.$refs.subtaskEditor.addSubTaskHandler();

      this.updateTaskTitle();
      if (!this.isInvalid) {
        this.isLoading = true;
        if (this.selectedUser) {
          this.taskData.members.push(this.selectedUser.id);
        }
        if (this.assignedMembers.length) {
          const memberId = this.assignedMembers.map((m) => m.id);
          this.taskData.members = [...memberId];
        }
        
        const driveData = this.makeDriveData();
        const task = {
          title: this.taskData.title,
          card_id: this.selectedCard.id,
          board_id: this.selectedWorkspace.boards[0].id,
          start_date: this.taskData.start_date,
          end_date: this.taskData.end_date,
          details: this.taskData.details,
          media: [],
          folder: this.folderList ? map(this.folderList, (o) => o.id) : [],
          media_urls: driveData,
          member: this.taskData.members,
          subtask: this.getSubtasks(),
          labels: this.taskData.labels,
          workspace_id: this.selectedWorkspace.id,
          calllink: this.taskData.calllink,
          is_lock: this.taskData.is_lock,
          is_invisible: this.taskData.is_invisible,
          cover_image: null,
        };

        const mediaIds = map(this.sortedFiles, (o) => {
          if(o.type.match(/image.*/) && !task.cover_image) {
            task.cover_image = o.id;
          }
          return o.id
        });
        task.media = mediaIds;
        if (this.taskData.includeAssignees && this.task.members.length) {
          const memberIds = map(this.task.members, (member) => member.id);
          task.member = memberIds;
        }
        this.addTask(task);
      }
    },
    async addTask(task) {
      const cardTasks = this.cardTasks[task.card_id];
      const activeTasks = cardTasks
        ? cardTasks.filter((task) => !task.completed)
        : cardTasks;
      const activeTasksOrdered = activeTasks
        ? orderBy(activeTasks, ["sequence"], ["asc"])
        : activeTasks;

      let sequence;
      if (activeTasksOrdered === undefined) {
        this.sequencePosition = this.sequencePosition
          ? this.sequencePosition
          : "top";
        if (this.sequencePosition === "top") {
          sequence = activeTasksOrdered
            ? RandomPosition.between(
                RandomPosition.first(),
                head(activeTasksOrdered).sequence
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
        } else {
          sequence = activeTasksOrdered
            ? RandomPosition.between(
                last(activeTasksOrdered).sequence,
                RandomPosition.last()
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
        }
      } else {
        if (this.sequencePosition === "top") {
          sequence =
            activeTasksOrdered.length > 0
              ? RandomPosition.between(
                  RandomPosition.first(),
                  head(activeTasksOrdered).sequence
                )
              : RandomPosition.between(
                  RandomPosition.first(),
                  RandomPosition.last()
                );
        } else {
          sequence =
            activeTasksOrdered.length > 0
              ? RandomPosition.between(
                  last(activeTasksOrdered).sequence,
                  RandomPosition.last()
                )
              : RandomPosition.between(
                  RandomPosition.first(),
                  RandomPosition.last()
                );
        }
      }

      const variables = {
        ...task,
        sequence,
      };
      const taskOwner = pick(this.user, [
        "id",
        "first",
        "last",
        "pic",
        "username",
      ]);
      const fakeTask = {
        __typename: "task",
        id: getUnixTime(new Date()),
        title: task.title,
        isbookmarked: false,
        details: task.details,
        completed: false,
        end_date: null,
        sequence: sequence,
        card_id: task.card_id,
        last_label: "notstarted",
        members: task.member,
        owner: {
          ...taskOwner,
          __typename: "user",
        },
        label: task.labels,
        subtasks: [],
        customFieldOptionSelection: [],
        rating: 0,
        activetimelog: null,
        timelogs: [],
        calllink: task.calllink,
        is_lock: task.is_lock,
        is_invisible: task.is_invisible,
        cover_image:task.cover_image
      };

      await this.$api
        .mutate({
          mutation: CreateDuplicateTaskMutation,
          variables,
        })
        .then(async (response) => {
          const boardQuery = this.$api.getQuery(
            `BoardQuery:${this.selectedWorkspace.boards[0].id}`
          );
          if (boardQuery.data) {
            boardQuery.data.board.tasks.push(fakeTask);
            boardQuery.data.board.tasks = boardQuery.data.board.tasks.map(
              (task) =>
                task === fakeTask ? response.data.createDuplicateTask : task
            );
          }
          if (this.taskObjFromMsg) {
            this.$q.notify({
              classes: "success-notification",
              position: "top-right",
              message: "Task created successfully",
              timeout: 2500,
              icon: this.$icons.matAnnouncement,
              actions: [{ icon: this.$icons.matClose, color: "white" }],
            });
          } else {
            this.$q.notify({
              classes: "success-notification",
              position: "top-right",
              message: "Task created",
              timeout: 2500,
              icon: this.$icons.matAnnouncement,
              actions: [{ icon: this.$icons.matClose, color: "white" }],
            });
          }
          this.isLoading = false;
          this.fileList = {};
          this.closeDialog();
          if (this.userId && this.showWorkspaceSection) {
            await this.$api.query({
              query: MyWorkQuery,
              variables: {
                user_id: parseInt(this.userId),
              },
              cacheKey() {
                return `MyWorkQuery:${parseInt(this.userId)}`;
              },
            });
          }
        });
    },
    addSubtaskAbove() {
      this.newSubtaskEditorVisibility = !this.newSubtaskEditorVisibility;
    },
    visibleSubtaskAdder() {
      if (!this.newSubtaskEditorVisibility) {
        if (!this.editSubtaskContent) {
          this.$nextTick(() => {
            if (document.getElementById("scrollableTaskDialog")) {
              document.getElementById("scrollableTaskDialog").scrollTop =
                document.getElementById("scrollableTaskDialog").scrollHeight -
                document.getElementById("scrollableTaskDialog").clientHeight;
            }
          });
        }
      }
    },
    addSubtask(obj) {
      if (this.editSubtask.flag) {
        this.taskData.subtasks[this.editSubtask.index].title = obj.newSubtask;
        this.taskData.subtasks[this.editSubtask.index].media = obj.optmedia;
        this.taskData.subtasks[this.editSubtask.index].uploadedFileIds =
          obj.uploadedFileIds;
        this.editSubtask.flag = false;
      } else {
        let newTask = {
          title: obj.newSubtask,
          user_id: this.user.id,
          media: obj.optmedia,
          uploadedFileIds: obj.uploadedFileIds,
          mediafolder: obj.mediafolder,
          owner: this.user,
          members: [],
          memberIds: [],
          childSubtasks: [],
        };
        this.taskData.subtasks.push(newTask);
      }
      this.$eventBus.$emit("resetCreateSubtaskEditable");
    },
    resetSubtaskEditor() {
      this.$refs.subtaskEditor.$refs.subtaskVisible.$refs.editor.resetTextArea();
      this.newSubtaskEditorVisibility = false;
      this.editSubtaskContent = null;
    },
    getSubtasks() {
      let newSubtasks = [];
      this.taskData.subtasks.map((subtask) => {
        let new_subtask = {
          title: subtask.title,
          user_id: subtask.user_id,
          media: subtask.uploadedFileIds,
          end_date: subtask.end_date,
          member: subtask.memberIds,
        };
        newSubtasks.push(new_subtask);
      });
      return newSubtasks;
    },
    updateSubtaskModHandler(args, index) {
      this.editSubtask.flag = true;
      this.editSubtask.index = index;
      this.newSubtaskEditorVisibility = true;
      this.editSubtaskContent = args;
    },
    deleteSubtask(index) {
      this.taskData.subtasks.splice(index, 1);
    },
  },
  computed: {
    startTimeDropdownOptions() {
      if (this.startDate === this.endDate) {
        const startTimeIndex = timeList.findIndex(
          (t) => t.value === this.endTime
        );
        return timeList.slice(0, startTimeIndex);
      }
      return timeList;
    },
    endTimeDropdownOptions() {
      if (this.startDate === this.endDate) {
        const endTimeIndex = timeList.findIndex(
          (t) => t.value === this.startTime
        );
        return timeList.slice(endTimeIndex + 1, timeList.length);
      }
      return timeList;
    },
    startDate() {
      if (this.dates) {
        return this.dates.hasOwnProperty("from") ? this.dates.from : this.dates;
      } else {
        return null;
      }
    },
    endDate() {
      return this.dates && this.dates.hasOwnProperty("to")
        ? this.dates.to
        : this.startDate;
    },
    isEndDateDue() {
      return isPast(
        parseISO(this.taskdates.to ? this.taskdates.to : this.taskdates.from)
      );
    },
    isStartDateDue() {
      return isPast(parseISO(this.taskdates.from));
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    descriptionData() {
      return compile(this.taskData.details);
    },
    uploadedFileIds() {
      if (this.fileList) {
        return map(
          filter(this.fileList, (media) => {
            if (
              (media.file_resource !== "google" &&
                media.file_resource !== "dropbox") ||
              media.verifyKey
            ) {
              return media;
            }
          }),
          (media) => media.id
        );
      } else {
        return [];
      }
    },
    uploadedFolderIds() {
      if (this.folderList) {
        return map(
          filter(this.folderList, (folder) => {
            return folder;
          }),
          (folder) => folder.id
        );
      } else {
        return [];
      }
    },
    uploadedMedia() {
      return this.uploadedFileIds.map((id) => this.$api.getEntity("media", id));
    },
    sortedFiles() {
      return orderBy([...this.uploadedMedia], ["sequence"]);
    },
    uploadedFolder() {
      return this.uploadedFolderIds.map((id) =>
        this.$api.getEntity("mediaFolder", id)
      );
    },
    sortedFolder() {
      return orderBy([...this.uploadedFolder], ["sequence"]);
    },
    outSourcedFiles() {
      return filter(this.fileList, (media) => {
        if (
          !media.verifyKey &&
          (media.file_resource === "google" ||
            media.file_resource === "dropbox")
        ) {
          return media;
        }
      });
    },
    totalFiles() {
      return [...this.sortedFiles, ...this.outSourcedFiles];
    },
    totalFolder() {
      return this.sortedFolder;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    selectedLabels: {
      get() {
        return this.taskData.labels.map((l) => l.id);
      },
    },
    myTaskLabel() {
      return this.taskLabels.filter((c) => c.user_id === this.user.id);
    },
    generalTaskLabel() {
      return this.taskLabels.filter((c) => c.user_id === null);
    },
    generalWorkspaceId() {
      return 1;
    },
    generalWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.generalWorkspaceId))
      );
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.selectedWorkspace &&
        this.workspaces.find((w) => w.id === Number(this.selectedWorkspace.id))
      );
    },
    canRemoveAttachment() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    isWorkspaceOwner() {
      if (this.selectedWorkspace && this.user) {
        return this.user.id === this.selectedWorkspace.owner.id;
      }
      return false;
    },
    cardTasks: {
      get() {
        return groupBy(
          orderBy(this.selectedWorkspace.boards[0].tasks, ["sequence"]),
          "card_id"
        );
      },
    },
    subtasks() {
      return this.taskData.subtasks;
    },
    workspaceAvailableUsersFuse() {
      if (this.selectedWorkspace && this.selectedWorkspace.users) {
        const orderedMembers = orderBy(
          this.selectedWorkspace.users,
          ["first", "last"],
          ["asc", "asc"]
        );
        return orderedMembers;
      }
      return [];
    },
    workspaceAvailableUsers() {
      return this.search
        ? this.workspaceAvailableUsersFuse.filter(
            (u) =>
              u.first.toLowerCase().includes(this.search.toLowerCase()) ||
              u.last.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.workspaceAvailableUsersFuse.hasOwnProperty("list")
        ? this.workspaceAvailableUsersFuse.list
        : this.workspaceAvailableUsersFuse;
    },
    workspacesFilterFuse() {
      if (this.workspaces) {
        return new Fuse(this.workspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return [];
    },
    filteredWorkspaces() {
      return this.searchWorkspaces
        ? this.workspacesFilterFuse.search(this.searchWorkspaces)
        : this.workspacesFilterFuse.hasOwnProperty("list")
        ? this.workspacesFilterFuse.list
        : this.workspacesFilterFuse;
    },
    fileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = this.sortedFiles.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = this.sortedFiles.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
  },
};
</script>
<style lang="stylus" scoped>
task-meber-box-h = 320px;
add-member-menu-btn = 35px;

.sel-task-member {
  height: 'calc(%s - 74px)' % task-meber-box-h;
  overflow-y: auto;
}

.add-member-menu-btn {
  width: add-member-menu-btn;
  height: add-member-menu-btn;
}

.user-pic {
  height: 50px !important;
  width: 50px !important;
}

.button-content {
  display: flex;
  justify-content: space-between;
  {/* width: 100%; */}
}
.list {
    .item {
      display: flex;
      align-items: center;
      padding: 5px 0px;
      cursor: pointer;
      color: #000000;
      width: 155px;
      // width: 160px;
      .user_pic {
        border: solid 1px #e3e8ed;
        background-color: #e3e8ed;
        span {
          color: #42526e;
          font-size: 10px;
          font-weight: 600;
        }
      }
      .close {
        opacity:0;
      }
    }
    .item:hover {
      .close {
        opacity:1;
      }
    }
  }
.assign-button {
  background-color: #dbecff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  .plus-icon {
    color: #007aff !important;
    position: absolute;
    top: 2px;
    right: 0;
    left: 1px;
    bottom: 0;
  }
  &.active {
    background-color: #007aff !important;
    .q-btn__content {
      span {
        color: #ffffff;
      }
      .q-icon {
        color: #ffffff;
      }
    }
  }
}
  .task-description-new-editor {
    font-weight: 500;
    font-style: normal;
    font-size: 15px !important;
    font-family: 'Avenir Next';
    .editor {
      padding: 0px;
    }
  }
  .task-description {
    padding-right: 10px !important;
    p {
      margin-top: 0px !important;
    }
  }
  .control-button {
    .q-btn__wrapper{
      .q-btn__content{
        span {
          font-size: 14px;
          margin-left:5px;
        }
        .button-content{
          font-size: 12px;
        }
      }
    }
  }

  .dropdown-set{
    .q-icon{
      font-size:18px;
    }
  }
</style>
