<template>
  <div class="bg-white relative-position">
    <bookmark-toolbar
      :pinPost="pinPosts"
      :bookmarks="bookmarks"
      :bookmarkFolders="bookmarkFolders"
      :labels="taskLabels"
    />
    <div
      v-if="mediaLoading && media.length === 0"
      style="height: 100vh"
      class="layout align-center justify-center"
    >
      <q-circular-progress
        indeterminate
        size="75px"
        :thickness="0.1"
        color="primary"
        class="q-mb-md"
      />
    </div>
    <div  v-else>
      <div
        @click.stop="$emit('closeNotificaiton')"
        style="max-height: calc(100vh - 124px)"
        class="window-height overflow-auto flex-1"
        v-infinite-scroll="fetchScrollHandler"
      >
        <div
          class="walkthrough walkthrough-large q-mx-md relative-position q-mb-md"
          v-if="user.onboarding.is_folder_detail"
        >
          <div class="icon">
            <q-icon
              :name="$icons.mdiFileMultiple"
              size="24px"
              style="color: #007aff"
            />
          </div>
          <div class="content">
            <div class="video" @click="videoPlayer">
              <q-icon
                class="play"
                color="primary"
                :name="$icons.mdiPlay"
                size="46px"
              />
            </div>
          </div>
          <div class="text-content q-ml-md column justify-between">
            <div>
              <p class="text-bold q-mb-none">Add files and create sections</p>
              <p class="q-mb-none">
                Add files by clicking the plus button in the top-right. Organize
                your files by adding sections.
              </p>
            </div>
            <div class="row">
              <!-- <q-btn
                dense
                no-caps
                color="primary"
                size="md"
                label="Learn more "
                class="info-btn"
                @click="$router.push({ name: 'LearnView' })"
              /> -->
              <q-btn
                dense
                no-caps
                size="md"
                label="Dismiss"
                class="q-ml-sm info-btn dismiss-btn"
                @click="toggleAboutWalkthrough(false)"
              />
            </div>
          </div>
          <div
            class="cursor-pointer"
            style="position: absolute; top: 16px; right: 16px"
            @click="toggleAboutWalkthrough(false)"
          >
            <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
          </div>
        </div>
        <div
          v-if="
            !user.onboarding.is_folder_detail &&
            imageList.length === 0 &&
            folderList.length === 0
          "
          style="max-height: calc(100vh - 60px)"
          class="row window-height justify-center q-mt-xl"
        >
          <div class="card text-center">
            <div
              class="card__title card__title--primary q-mt-md q-pa-sm"
              style="background: #f7f9fc"
            >
              <div>
                <q-avatar :style="{ background: 'rgba(0, 196, 134, 0.2)' }">
                  <q-icon
                    color="teal-14"
                    size="24px"
                    :name="$icons.matDescription"
                  />
                </q-avatar>
                <div
                  class="title nolabel--text q-mt-sm q-mb-xs an-15 medium-text"
                >
                  Media Hub
                </div>
                <span class="nolabel--text an-15 regular-text">
                  There are no files shared
                  <br />in this workspace.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <component
              :is="mediaView"
              :imageList="imageList"
              :folderList="folderList"
              :workspace="currentWorkspaceId"
              :isWorkspaceOwner="isWorkspaceOwner"
              :canRemoveMedia="canRemove"
              :user="user"
              :currentCompanyId="currentCompany.companyId"
              :isloading="isloading"
              :activeCompany="activeCompany"
              :removeloadButton="removeloadButton"
              :addMediaBookmark="addMediaBookmark"
              :removeMediaBookmark="removeMediaBookmark"
              :editDoc="editDoc"
              :openDocEditor="openDocEditor"
              @paginate="fetchMoreMedia"
              @delete="removeMedia"
              @clickonmoreoption="clickonmoreoption"
              @deleteCommentPoint="deleteCommentPoint"
              @updatepositions="updatepositions"
              @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
              @updateComment="updateComment"
              @sendComment="sendComment"
              @deleteMediaComment="deleteMediaComment"
              @closeMediaModel="closeMediaModel"
              :labels="taskLabels"
              :fromComputer="(data) => fromComputer(data)"
              :uploadFileToFolder="(data) => uploadFileToFolder(data)"
              :authToken="authToken"
            ></component>
          </div>
        </div>
      </div>
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="currentWorkspaceId"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
      @setProgress="setProgress"
      :media_id="mediaId"
    ></vue-dropzone-file-upload>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <file-uploader
      dropzoneid="media-view"
      v-model="fileUpload.dialog"
      :authToken="authToken"
      :workspace="currentWorkspaceId"
      :noquery="false"
      :user="user"
      :currentCompanyId="currentCompany.companyId"
      :imageList="imageList"
      :labels="taskLabels"
      :isWorkspaceOwner="isWorkspaceOwner"
      ref="mediaFileUploader"
      :isMediaViewUploader="true"
      @done="mediaFileUploadCloseHandler"
      @cancel="fileUpload.dialog = false"
      @clickonmoreoption="clickonmoreoption"
      @deleteCommentPoint="deleteCommentPoint"
      @updatepositions="updatepositions"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
      @updateComment="updateComment"
      @sendComment="sendComment"
      @deleteMediaComment="deleteMediaComment"
      @closeMediaModel="closeMediaModel"
    ></file-uploader>
    <button :class="['fab-btn']"  v-if="isVisible([1, 2, 3])">
      <q-icon :name="$icons.mdiPlusThick" size="20px" color="white" />
      <q-menu
        content-style="width:170px;max-height:200px;box-shadow:0px 2px 10px 0px rgb(0 0 0 / 19%)"
        v-model="fabMenu"
        auto-close
        :offset="[-20, 0]"
      >
        <q-list class="q-py-sm">
          <q-item
              @click="() => googleDrive(null, user.id)"
              clickable
              :class="['items-center q-px-lg']"
              style="font-size: 15px"
            >
              <q-icon :name="$icons.fabGoogleDrive" size="18px"
              style="margin-right: 20px;" />
              Google Drive
          </q-item>
          <q-item
            @click="() => dropBox(null, user.id)"
            clickable
            :class="['items-center q-px-lg']"
            style="font-size: 15px"
          >
              <q-icon :name="$icons.fabDropbox" size="18px"
              style="margin-right: 20px;" />
              Dropbox
          </q-item>
          <q-separator style="background:#ECEEF1;" />
          <q-item
            @click="openDocEditor(currentWorkspaceId)"
            :class="['items-center q-px-lg ']"
            clickable
            style="font-size: 15px"
          >
            <q-icon
              :name="$icons.matDescription"
              size="18px"
              style="margin-right: 20px"
            />
            New doc
          </q-item>
          <q-item
            @click="createNewTask"
            :class="['items-center q-px-lg ']"
            clickable
            style="font-size: 15px"
          >
            <q-icon
              size="18px"
              :name="$icons.fasCheck"
              style="margin-right: 20px"
            />
            New task
          </q-item>
          <q-separator style="background:#ECEEF1;" />
          <q-item
            @click="uploadFiles"
            :class="['items-center q-px-lg ']"
            clickable
            style="font-size: 15px"
          >
            <q-icon
              :name="$icons.mdiFileUpload"
              size="18px"
              style="margin-right: 20px"
            />
            Upload files
          </q-item>
          <q-item
            @click="checkMediaFolderAccess"
            :class="['items-center q-px-lg ']"
            clickable
            style="font-size: 15px"
          >
            <q-icon
              :name="$icons.matFolder"
              size="18px"
              style="margin-right: 20px"
            />
            Create folder
          </q-item>
        </q-list>
      </q-menu>
    </button>
    <!-- <div class="create-new-task-doc row items-center">
      <div
        @click="createNewTask"
        class="btn task row items-center q-px-sm cursor-pointer q-mr-md"
      >
        <q-tooltip>
          Create new task
        </q-tooltip>
        <div class="btn-icon">
          <q-icon size="18px" :name="$icons.mdiPlusThick" />
        </div>
        <div class="btn-lbl flex-1 q-ml-sm">
          Task
        </div>
      </div>
      <div
        class="btn doc row items-center q-px-sm cursor-pointer bg-positive"
        @click="openDocEditor"
      >
        <q-tooltip>
          Create new doc
        </q-tooltip>
        <div class="btn-icon">
          <q-icon size="18px" :name="$icons.mdiPlusThick" />
        </div>
        <div class="btn-lbl flex-1 q-ml-sm">
          Doc
        </div>
      </div>
    </div> -->
    <new-task-dialog
      v-if="addTaskSection"
      v-model="addTaskSection"
      :closeDialog="closeNewTaskDialog"
      :workspace="currentWorkspace"
      :taskLabels="taskLabels"
      :userId="user.id"
      :showWorkspaceSection="false"
      :sequencePosition="'bottom'"
      :taskObjFromMsg="taskObjFromMsg"
    />
    <document-editor-dialog
      :key="createdDoc.id"
      v-if="documentDialog"
      v-model="documentDialog"
      :openDocEditor="openDocEditor"
      :closeDocEditor="closeDocEditor"
      :documentProp="createdDoc"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
    />
    <create-media-folder-dialog
      v-if="createMediaFolderDialog.flag"
      v-model="createMediaFolderDialog.flag"
      :loading="createMediaFolderDialog.loading"
      :createFolderHandler="createFolderHandler"
      :cancelMethod="cancel"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import map from "lodash/map";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import last from "lodash/last";

import GridView from "@/views/MediaView/GridView";
import ListView from "@/views/MediaView/ListView";

import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import MediaViewMixin from "@/mixins/MediaViewMixin";
import UserScope from "@/mixins/UserScope";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";


import FileUploader from "@/components/Miscellaneous/FileUploader";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import NewTaskDialog from "@/views/MyWorkView/NewTaskDialog";
import BookmarkToolbar from "@/views/WorkspaceView/BookmarkToolbar";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";
import CreateMediaFolderDialog from "@/components/Dialogs/CreateMediaFolderDialog";

import {
  GetNewMediaListMutation,
  NewMediaQuery,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
  UpdateUserOnboardingMutation,
  CreateMediaFolderMutation,
} from "@/gql";

export default {
  name: "MediaView",
  props: ["mediaSearch", "drawerRight", "mediaView", "toggleDrawerRight"],
  mixins: [UserScope, MediaMethodMixin, DocumentEditorMixin, GoogleDriveMethodMixin, DropBoxMixin, CopiedImageOnEditor, MediaViewMixin],
  components: {
    FileUploader,
    GridView,
    ListView,
    VueDropzoneFileUpload,
    VideoDialog,
    NewTaskDialog,
    BookmarkToolbar,
    DocumentEditorDialog,
    CreateMediaFolderDialog,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspaceId}`;
      },
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
      defaultValue: [],
    },
    pinPosts: {
      defaultValue: [],
      cacheKey() {
        return `PinMessagesQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarks: {
      defaultValue: [],
      cacheKey() {
        return `BookmarksQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarkFolders: {
      defaultValue: [],
      cacheKey() {
        return `BookmarkFoldersQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  data() {
    return {
      removeloadButton: false,
      isloading: false,
      mediaId: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      addTaskSection: false,
      taskObjFromMsg: null,
      fabMenu: false,
      createMediaFolderDialog: {
        flag: false,
        loading: false,
      },
    };
  },
  methods: {
    async mediaFileUploadCloseHandler(fileList) {
      const mediaIds = fileList ? map(fileList, (f) => f.id) : [];
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;

      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });

      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspaceId}`);
      query.data.newMedia.push(...response.data.getNewMediaList);
      this.$q.loadingBar.stop();
    },
    uploadFiles() {
      this.fileUpload.dialog = true;
      this.$refs.mediaFileUploader.openFileDialog();
    },
    cancel() {
      this.createMediaFolderDialog.flag = false;
      this.createMediaFolderDialog.loading = false;
    },
    async createFolderHandler(title) {
      this.createMediaFolderDialog.loading = true;
      try {
        const variables = {
          title,
          workspace_id: this.currentWorkspaceId,
        };
        const response = await this.$api.mutate({
          mutation: CreateMediaFolderMutation,
          variables,
        });
        if (response) {
          const mediaFoldersQuery = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspaceId}`
          );
          const mediaFolder = {
            ...response.data.createMediaFolder,
            __typename: "mediaFolder",
            media_count: 0,
          };
          if (mediaFoldersQuery.data && mediaFoldersQuery.data.mediaFolders) {
            mediaFoldersQuery.data.mediaFolders.push(mediaFolder);
          } else {
            mediaFoldersQuery.data.mediaFolders = [mediaFolder];
          }
        }
        this.createMediaFolderDialog.loading = false;
        this.createMediaFolderDialog.flag = false;
        // this.$mixpanelEvent("create-folder", {});
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.createMediaFolderDialog.loading = false;
        this.createMediaFolderDialog.flag = false;
      }
    },
    checkMediaFolderAccess() {
      let eligible = this.$checkEligiblity("file_folders");
      if (eligible) {
        this.createMediaFolderDialog.flag = true;
      }
    },
    createNewTask() {
      this.addTaskSection = true;
    },
    closeNewTaskDialog() {
      this.addTaskSection = false;
      this.taskObjFromMsg = null;
    },
    async toggleAboutWalkthrough(flag) {
      this.user.onboarding.is_folder_detail = flag;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_folder_detail",
          flag_value: flag,
        },
      });
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/662770213",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    async fileUploadCloseHandler(fileList) {
      console.log("this is testing", fileList);
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;

      if (this.mediaId) {
        const media = this.$api.getEntity("media", this.mediaId);
        media.file = fileList[this.mediaId].file;
        media.thumb = fileList[this.mediaId].thumb;
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    fromComputer(media_id) {
      this.mediaId = media_id;
      this.$refs.vueFileUploader.openFileDialog();
    },
    async fetchMoreMedia() {
      this.isloading = true;
      const lastMedia = last(this.media);
      if (lastMedia) {
        const variables = {
          workspace_id: this.currentWorkspaceId,
          cursor: lastMedia.id,
          cursor_type: "prev",
          limit: 10,
        };

        const currentMedia = this.$api.getQueryByName("media");
        const newMedia = await this.$api.query({
          query: NewMediaQuery,
          variables,
        });

        if (newMedia.data.newMedia.length === 0) {
          this.removeloadButton = true;
        } else {
          currentMedia.data.newMedia = [
            ...currentMedia.data.newMedia,
            ...newMedia.data.newMedia,
          ];
        }
      }

      this.isloading = false;
    },
    fetchScrollHandler() {
      if (this.isloading === false) {
        this.fetchMoreMedia();
      }
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        this.$mixpanelEvent("save-file", {});
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : null;
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    canRemove() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(
          orderBy(
            this.mediaFolders.filter((o) =>
              o.title
                .toLowerCase()
                .includes(this.mediaSearch.searchText.toLowerCase())
            ),
            ["title"],
            ["asc"]
          ),
          "id"
        );
      } else {
        return [];
      }
    },
    imageList() {
      if (this.media) {
        return uniqBy(
          orderBy(
            this.media.filter(
              (o) =>
                (o.filename &&
                  o.filename
                    .toLowerCase()
                    .includes(this.mediaSearch.searchText.toLowerCase())) ||
                o.file_resource
                  .toLowerCase()
                  .includes(this.mediaSearch.searchText.toLowerCase())
            ),
            ["id"],
            ["desc"],
          ),
          "id"
        );
      } else {
        return [];
      }
    },
  },
  watch: {
    // imageList(val) {
    //   if (val && val.length < 50) {
    //     this.fetchMoreMedia();
    //   } else {
    //     this.removeloadButton = false;
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.create-new-task-doc {
  position: absolute;
  bottom: 20px;
  right: 25px;
  .btn {
    height: 35px;
    width: 85px;
    color: white;
    border-radius: 20px;
    transition: 0.5s all ease-in-out;
    z-index: 100;
    &:hover {
      transform: scale(1.2);
      transition: 0.5s all ease-in-out;
    }
    &.task {
      background: #007aff;
    }
    &.doc {
      background: #15d89a;
    }
  }
  .btn-icon {
  }
  .btn-lbl {
    font-size: 14px;
    font-weight: bold;
  }
}
.fab-btn {
  position: absolute;
  bottom: 20px;
  right: 25px;
  width: 45px;
  height: 45px;
  background: #007aff;
  border-radius: 12px;
  // box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
  border: 3px solid #dbecff;
}
</style>
